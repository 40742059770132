import {has} from 'lodash';
import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {MODULE_TYPES, TIERS} from '../../../constants';
import NotFoundModal from '../../../containers/modal/not_found_modal';
import {SettingsProfile} from '../../../containers/settings/settings_profile';
import UpdatePersonMutation from '../../../mutations/update_person_mutation.modern';
import {PERMISSION_TYPE} from '../../../Permissions';
import * as tracking from '../../../tracking';
import {trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import {usesSso} from '../../shared/util/FeatureUtil';
import {hasModule} from '../../shared/util/ModuleUtil';
import {
	getProfile,
	personHasContributerProfile,
	personHasPermission,
	personIsAdmin,
	personIsVirtualUser,
} from '../../shared/util/PermissionsUtil';
import Util from '../../shared/util/util';

class SettingsProfilePerson extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isUploading: false,
			showEmailValidationError: false,
			emailValidationErrorMessage: '',
			isProfileUpdating: false,
		};

		this.isContactPerson = !!this.props.viewer.company.contactPersons.edges.filter(
			e => e.node.person.id === this.props.viewer.company.person.id
		);
		this.superPropertyChecksum = trackPage('Settings Team Member Profile');
	}

	componentDidMount() {
		// Segment
		tracking.trackPage('settings-view-user');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	settingsRef = React.createRef();

	isEmptyString = obj => typeof obj !== 'string' || obj.trim().length === 0;

	onUpdateEmail(result) {
		if (result.updatePerson.errors && result.updatePerson.errors.length > 0) {
			this.setState({
				showEmailValidationError: true,
				emailValidationErrorMessage: 'Email already exists',
			});
		} else {
			this.setState({
				showEmailValidationError: false,
				emailValidationErrorMessage: '',
			});
		}
	}

	changeName(firstName, lastName) {
		if (this.isContactPerson && this.isEmptyString(firstName) && this.isEmptyString(lastName)) {
			if (this.settingsRef && this.settingsRef.current) {
				this.settingsRef.current.handleChangeName(
					this.props.viewer.company.person.firstName + ' ' + this.props.viewer.company.person.lastName
				);
			}
		} else if (
			firstName !== this.props.viewer.company.person.firstName ||
			lastName !== this.props.viewer.company.person.lastName
		) {
			Util.CommitMutation(UpdatePersonMutation, {
				id: this.props.viewer.company.person.id,
				firstName,
				lastName,
			});
		}
	}

	changePhone(phone) {
		if (phone !== this.props.viewer.company.person.phone) {
			Util.CommitMutation(UpdatePersonMutation, {
				id: this.props.viewer.company.person.id,
				phone,
			});
		}
	}

	changeEmail(email) {
		if (this.isContactPerson && this.isEmptyString(email)) {
			if (this.settingsRef && this.settingsRef.current) {
				this.settingsRef.current.handleChangeEmail(this.props.viewer.company.person.email);
			}
		} else if (email !== this.props.viewer.company.person.email) {
			const data = {
				id: this.props.viewer.company.person.id,
				email,
			};
			if (personIsAdmin(this.props.viewer.company.person) && !usesSso(this.props.viewer.company)) {
				showModal({
					type: MODAL_TYPE.CONFIRM_PASSWORD_MODAL,
					mutation: UpdatePersonMutation,
					mutationName: 'updatePerson',
					data,
					onSuccess: this.onUpdateEmail.bind(this),
					defaultCallback: () => {
						if (this.settingsRef && this.settingsRef.current) {
							this.settingsRef.current.handleChangeEmail(this.props.viewer.company.person.email);
						}
					},
				});
			} else {
				Util.CommitMutation(UpdatePersonMutation, data, this.onUpdateEmail.bind(this));
			}
		}
	}

	changeJobTitle(jobTitle) {
		if (this.isContactPerson && this.isEmptyString(jobTitle)) {
			if (this.settingsRef && this.settingsRef.current) {
				this.settingsRef.current.handleChangeJobTitle(this.props.viewer.company.person.jobTitle);
			}
		} else if (jobTitle !== this.props.viewer.company.person.jobTitle) {
			Util.CommitMutation(UpdatePersonMutation, {
				id: this.props.viewer.company.person.id,
				jobTitle,
			});
		}
	}

	changeRole(roleId) {
		Util.CommitMutation(UpdatePersonMutation, {
			id: this.props.viewer.company.person.id,
			roleId,
		});
	}

	changeDepartment(departmentId) {
		Util.CommitMutation(UpdatePersonMutation, {
			id: this.props.viewer.company.person.id,
			departmentId,
		});
	}

	changeLanguage(language) {
		Util.CommitMutation(UpdatePersonMutation, {
			id: this.props.viewer.company.person.id,
			language,
		});
	}

	changeHolidayCalendar(holidayCalendarId) {
		Util.CommitMutation(UpdatePersonMutation, {
			id: this.props.viewer.company.person.id,
			holidayCalendarId,
		});
	}

	changeProfile(profile) {
		const onSuccess = res => {
			if (has(res, 'updatePerson.errors') && res.updatePerson.errors && res.updatePerson.errors.length > 0) {
				const errors = res.updatePerson.errors;
				const {company} = this.props.viewer;
				if (errors.includes('NO_AVAILABLE_VIRTUAL_SEATS')) {
					showModal({
						type: MODAL_TYPE.NO_AVAILABLE_SEATS,
						seats: company.virtualSeats,
						seatType: 'virtual ',
						warningMessageId: 'team_modal.seats_warning_permission_change_failed',
					});
				} else if (errors.includes('NO_AVAILABLE_SEATS')) {
					showModal({
						type: MODAL_TYPE.NO_AVAILABLE_SEATS,
						seats: company.userSeats,
						seatType: company.tier === 'CORE' ? 'core ' : 'full ',
						warningMessageId: 'team_modal.seats_warning_permission_change_failed',
					});
				} else if (errors.includes('NO_DESIGNER_SEATS_LEFT') || errors.includes('NO_VIEWER_SEATS_LEFT')) {
					showModal({
						type: MODAL_TYPE.WARNING,
						message: 'Not enough Advanced Analytics seats',
						warningInformation: ['Please contact your account manager if you need to increase your seat limit.'],
					});
				} else if (errors.includes('NO_AVAILABLE_COLLABORATOR_SEATS')) {
					showModal({
						type: MODAL_TYPE.NO_AVAILABLE_SEATS,
						seats: company.collaboratorSeats,
						seatType: 'collaborator ',
						warningMessageId: 'team_modal.seats_warning_permission_change_failed',
					});
				}
			}
			this.setState({isProfileUpdating: false});
		};
		const data = {
			id: this.props.viewer.company.person.id,
		};

		if (hasModule(MODULE_TYPES.SISENSE)) {
			const selectingVirtual = profile.some(p => +atob(p.value).replace('Profile:', '') === -1);
			const selectingCollaborator = profile.some(p => +atob(p.value).replace('Profile:', '') === -4);
			const {person} = this.props.viewer.company;
			if (!personIsVirtualUser(person) && selectingVirtual) {
				// Changing to virtual
				data.profileIds = [btoa('Profile:-1')];
			} else if (personIsVirtualUser(person) && profile.length === 2) {
				// Changing from virtual
				data.profileIds = profile.map(p => p.value).filter(p => +atob(p).replace('Profile:', '') !== -1);
			} else if (!personHasContributerProfile(person) && selectingCollaborator) {
				// Changing to Collaborator seat / Contributer profile
				data.profileIds = [btoa('Profile:-4')];
			} else if (personHasContributerProfile(person) && profile.length === 2) {
				// Changing from Collaborator seat / Contributer profile
				data.profileIds = profile.map(p => p.value).filter(p => +atob(p).replace('Profile:', '') !== -4);
			} else {
				data.profileIds = profile.map(p => p.value);
			}
		} else {
			data.profileIds = [profile.value];
		}

		const companyProfiles = this.props.viewer.company.profiles.edges.map(e => e.node);
		const isAdmin = personIsAdmin(this.props.viewer.company.person);
		const changeToAdmin = data.profileIds.some(profileId =>
			personHasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS, getProfile(companyProfiles, profileId))
		);
		if (!isAdmin && changeToAdmin && !usesSso(this.props.viewer.company)) {
			showModal({
				type: MODAL_TYPE.CONFIRM_PASSWORD_MODAL,
				mutation: UpdatePersonMutation,
				mutationName: 'updatePerson',
				onSuccess: onSuccess,
				data,
			});
		} else {
			this.setState({isProfileUpdating: true});
			Util.CommitMutation(UpdatePersonMutation, data, onSuccess);
		}
	}

	changeProfilePicture(file) {
		this.setState({isUploading: true});
		const onSuccess = result => {
			//Id of picture was not changed, so the picture was not changed, this happens when you try to upload a gif that is too large
			if (result.updatePerson.errors && result.updatePerson.errors.includes('GIF_TOO_LARGE')) {
				createToast({duration: 5000, message: <FormattedMessage id="common.gif_too_large" />});
			}
			this.setState({isUploading: false});
		};
		Util.CommitMutation(UpdatePersonMutation, {id: this.props.viewer.company.person.id}, onSuccess, true, {
			file,
		});
	}

	deleteProfilePicture() {
		Util.CommitMutation(UpdatePersonMutation, {
			id: this.props.viewer.company.person.id,
			profilePictureId: 'delete',
		});
	}

	closeModal() {
		this.props.history.goBack();
	}

	render() {
		let showBillingInfo = [TIERS.LITE, TIERS.PRO, TIERS.ENTERPRISE].includes(this.props.viewer.company.tier);
		if (!this.props.viewer.company.person) {
			const {formatMessage} = this.props.intl;
			return (
				<NotFoundModal
					title={formatMessage({id: 'person_not_found_modal.title'})}
					message={formatMessage({id: 'person_not_found_modal.info'})}
					closeModal={this.closeModal.bind(this)}
				/>
			);
		}

		return (
			<SettingsProfile
				ref={this.settingsRef}
				children={this.props.children}
				person={this.props.viewer.company.person}
				viewer={this.props.viewer}
				language={this.props.viewer.language}
				isViewer={false}
				company={this.props.viewer.company}
				showEmailValidationError={this.state.showEmailValidationError}
				emailValidationErrorMessage={this.state.emailValidationErrorMessage}
				viewUser={true}
				changeName={this.changeName.bind(this)}
				changeEmail={this.changeEmail.bind(this)}
				changeJobTitle={this.changeJobTitle.bind(this)}
				changePhone={this.changePhone.bind(this)}
				changeRole={this.changeRole.bind(this)}
				changeProfile={this.changeProfile.bind(this)}
				changeDepartment={this.changeDepartment.bind(this)}
				changeLanguage={this.changeLanguage.bind(this)}
				changeProfilePicture={this.changeProfilePicture.bind(this)}
				deleteProfilePicture={this.deleteProfilePicture.bind(this)}
				changeHolidayCalendar={this.changeHolidayCalendar.bind(this)}
				isUploading={this.state.isUploading}
				showBillingInfo={showBillingInfo}
				currency={this.props.viewer.company.currency}
				viewerId={this.props.viewer.id}
				isProfileUpdating={this.state.isProfileUpdating}
			/>
		);
	}
}

const settingsProfilePersonQuery = graphql`
	query settingsProfilePerson_Query($userId: ID) {
		viewer {
			actualPersonId
			component(name: "settings_profile")
			...settingsProfilePerson_viewer @arguments(userId: $userId)
		}
	}
`;

export {settingsProfilePersonQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(SettingsProfilePerson, {
			viewer: graphql`
				fragment settingsProfilePerson_viewer on Viewer @argumentDefinitions(userId: {type: "ID"}) {
					id
					language
					availableFeatureFlags {
						key
					}
					company {
						id
						name
						tier
						modules {
							moduleType
						}
						currency
						userSeats
						virtualSeats
						collaboratorSeats
						jiraCloudEnabled
						jiraServerEnabled
						harvestEnabled
						gitlabEnabled
						gitlabServerEnabled
						githubEnabled
						vstsEnabled
						unit4Enabled
						bambooHREnabled
						newSlackEnabled
						sageIntacctConnectionEnabled
						hiBobEnabled
						hiBobConnectionEnabled
						oktaEnabled
						oneloginEnabled
						AADEnabled
						weekendDisplayPerUser
						weekendDisplayShowAlways
						contactPersons {
							edges {
								node {
									id
									contactType
									person {
										id
										fullName
										email
										phone
									}
								}
							}
						}
						profiles(first: 100000) {
							edges {
								node {
									id
									name
									canBeEdited
									personsCount
									permissions
									disabled
								}
							}
						}
						departments(first: 1000) {
							edges {
								node {
									id
									name
								}
							}
						}
						roles(first: 1000) {
							edges {
								node {
									id
									name
								}
							}
						}
						...ProfileSkills_company
						holidayCalendars(first: 10000) {
							edges {
								node {
									id
									name
								}
							}
						}
						labels(first: 10000, labelType: PERSON) {
							edges {
								node {
									id
									name
									color
									category {
										id
										name
									}
									...LabelDropdown_labels
								}
							}
						}
						person(id: $userId) {
							id
							seat
							client {
								id
							}
							initials
							language
							firstName
							lastName
							phone
							email
							jobTitle
							department {
								id
								name
							}
							costCenter
							demoUser
							language
							monday
							tuesday
							wednesday
							thursday
							friday
							saturday
							sunday
							startDate
							endDate
							calendarKey
							cost
							holidayCalendar {
								id
							}
							role {
								id
								name
							}
							client {
								id
							}
							personLabels(first: 10000) {
								edges {
									node {
										label {
											id
											name
											color
										}
									}
								}
							}
							skillPersons {
								skill {
									id
								}
								level {
									id
								}
							}
							profiles(first: 10000) {
								edges {
									node {
										id
										name
									}
								}
							}
							permissions
							invited
							active
							hasLoggedIn
							profilePictureId
							profilePictureDefaultId
							gitlabUser {
								integrationUserId
							}
							gitlabServerUser {
								integrationUserId
							}
							githubUser {
								integrationUserId
							}
							unit4UserObject
							harvestUser
							jiraCloudId
							jiraServerId
							bambooHRId
							sisenseUserId
							slackId
							sageIntacctId
							sageIntacctName
							sageIntacctEmail
							sageIntacctLocationId
							hiBobId
							adoUserId
						}
					}
				}
			`,
		})
	)
);
