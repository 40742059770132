/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SettingsCustomFields_viewer$ref = any;
export type SettingsCustomFieldsQueryVariables = {||};
export type SettingsCustomFieldsQueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: SettingsCustomFields_viewer$ref,
  |}
|};
export type SettingsCustomFieldsQuery = {|
  variables: SettingsCustomFieldsQueryVariables,
  response: SettingsCustomFieldsQueryResponse,
|};
*/


/*
query SettingsCustomFieldsQuery {
  viewer {
    actualPersonId
    component(name: "settings_custom_fields")
    ...SettingsCustomFields_viewer
    id
  }
}

fragment CustomFieldDefinitionRow_company on Company {
  id
}

fragment CustomFieldDefinitionRow_customFieldDefinition on CustomFieldDefinition {
  id
  key
  displayName
  readOnly
  entityType
  avaRef
}

fragment SettingsCustomFields_viewer on Viewer {
  actualPersonId
  id
  company {
    ...CustomFieldDefinitionRow_company
    customFieldDefinitions(first: 1000) {
      edges {
        node {
          id
          key
          entityType
          ...CustomFieldDefinitionRow_customFieldDefinition
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "settings_custom_fields"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"settings_custom_fields\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsCustomFieldsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SettingsCustomFields_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SettingsCustomFieldsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "CustomFieldDefinitionTypeConnection",
                "kind": "LinkedField",
                "name": "customFieldDefinitions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomFieldDefinitionTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomFieldDefinition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "key",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "entityType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "readOnly",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "avaRef",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "customFieldDefinitions(first:1000)"
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Company_customFieldDefinitions",
                "kind": "LinkedHandle",
                "name": "customFieldDefinitions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SettingsCustomFieldsQuery",
    "operationKind": "query",
    "text": "query SettingsCustomFieldsQuery {\n  viewer {\n    actualPersonId\n    component(name: \"settings_custom_fields\")\n    ...SettingsCustomFields_viewer\n    id\n  }\n}\n\nfragment CustomFieldDefinitionRow_company on Company {\n  id\n}\n\nfragment CustomFieldDefinitionRow_customFieldDefinition on CustomFieldDefinition {\n  id\n  key\n  displayName\n  readOnly\n  entityType\n  avaRef\n}\n\nfragment SettingsCustomFields_viewer on Viewer {\n  actualPersonId\n  id\n  company {\n    ...CustomFieldDefinitionRow_company\n    customFieldDefinitions(first: 1000) {\n      edges {\n        node {\n          id\n          key\n          entityType\n          ...CustomFieldDefinitionRow_customFieldDefinition\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '39ed1c98b9ca08fce7e0b1d5ab1f7361';

module.exports = node;
