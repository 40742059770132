const newPhaseTaskAndTimeColumns = [
    {
        key: "phase_name",
        label: "Phase Name",
        validations: [
            {
                validate: "required_with",
                columns: ["phase_start_date_yyyymmdd", "phase_end_date_yyyymmdd"],
            },
        ],
    },
    {
        key: "phase_start_date_yyyymmdd",
        label: "Phase start date (YYYY-MM-DD)",
        columnType: "date",
        outputFormat: "YYYY-MM-DD",
    },
    {
        key: "phase_end_date_yyyymmdd",
        label: "Phase end date (YYYY-MM-DD)",
        columnType: "date",
        outputFormat: "YYYY-MM-DD",
    },
    {
        key: "task_name",
        label: "Task Name",
        validations: [
            {
                validate: "required_with",
                columns: [
                    "task_description",
                    "task_estimate_minutes",
                    "task_workflow_status",
                    "task_start_date_yyyymmdd",
                    "deadline_yyyymmdd",
                    "task_blocked_truefalse",
                    "task_bug_truefalse",
                    "task_bilable_truefalse",
                    "high_priority_truefalse",
                    "task_assigned_comma_seperated_emails",
                    "task_owner_email",
                    "time_registration_minutes",
                ],
            },
        ],
    },
    {
        key: "task_description",
        label: "Task Description",
    },
    {
        key: "task_estimate_minutes",
        label: "Task Estimate (minutes)",
        columnType: "int",
    },
    {
        key: "task_workflow_status",
        label: "Task Workflow Status",
    },
    {
        key: "task_start_date_yyyymmdd",
        label: "Task Start Date (YYYY-MM-DD)",
        columnType: "date",
        outputFormat: "YYYY-MM-DD",
    },
    {
        key: "deadline_yyyymmdd",
        label: "Deadline (YYYY-MM-DD)",
        columnType: "date",
        outputFormat: "YYYY-MM-DD",
    },
    {
        key: "task_blocked_truefalse",
        label: "Task Blocked (TRUE/FALSE)",
        validations: [],
        columnType: "category",
        dropdownOptions: [
            {
                label: "TRUE",
                value: "TRUE",
                type: "string",
            },
            {
                label: "FALSE",
                value: "FALSE",
                type: "string",
            },
        ],
    },
    {
        key: "task_bug_truefalse",
        label: "Task Bug (TRUE/FALSE)",
        dropdownOptions: [
            {
                label: "TRUE",
                value: "TRUE",
                type: "string",
            },
            {
                label: "FALSE",
                value: "FALSE",
                type: "string",
            },
        ],
    },
    {
        key: "task_bilable_truefalse",
        label: "Task Bilable (TRUE/FALSE)",
        dropdownOptions: [
            {
                label: "TRUE",
                value: "TRUE",
                type: "string",
            },
            {
                label: "FALSE",
                value: "FALSE",
                type: "string",
            },
        ],
    },
    {
        key: "high_priority_truefalse",
        label: "High Priority (TRUE/FALSE)",
        dropdownOptions: [
            {
                label: "TRUE",
                value: "TRUE",
                type: "string",
            },
            {
                label: "FALSE",
                value: "FALSE",
                type: "string",
            },
        ],
    },
    {
        key: "task_assigned_comma_seperated_emails",
        label: "Task Assigned (e-mail)",
    },
    {
        key: "task_owner_email",
        label: "Task Owner (e-mail)",
        validations: [
            {
                validate: "regex",
                regex: "^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|'(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*')@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])$",
                errorMessage: "The input is invalid. Please enter a valid email address, e.g. max@company.com.",
            },
        ],
    },
    {
        key: "time_registration_minutes",
        label: "Time Registration (minutes)",
        columnType: "int",
        validations: [
            {
                validate: "required_with",
                columns: [
                    "time_registration_notes",
                    "time_registration_person_email",
                    "time_registration_date_yyyymmdd",
                ],
            },
        ],
    },
    {
        key: "time_registration_notes",
        label: "Time Registration Notes",
    },
    {
        key: "time_registration_person_email",
        label: "Time Registration Person (e-mail)",
        validations: [
            {
                validate: "regex",
                regex: "^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|'(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*')@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])$",
                errorMessage: "The input is invalid. Please enter a valid email address, e.g. max@company.com.",
            },
            {
                validate: "required_with",
                columns: ["time_registration_minutes"],
            },
        ],
    },
    {
        key: "time_registration_date_yyyymmdd",
        label: "Time Registration Date (YYYY-MM-DD)",
        columnType: "date",
        outputFormat: "YYYY-MM-DD",
        validations: [
            {
                validate: "required_with",
                columns: ["time_registration_minutes"],
            },
        ],
    },
];
export function settingsForExistingProjectsAndTasks() {
    return {
        developerMode: true,
        title: "Import time entries into existing tasks or projects.",
        completeImportAction: "block",
        identifier: "time_entries_to_existing_task_projects",
        modal: false,
        allowCustomOptions: true,
        columns: [
            {
                key: "existing_project_id_or_task_id",
                label: "Existing Project ID or Task ID (e.g. P17 or T324)",
            },
            {
                key: "time_registration_minutes",
                label: "Time Registration (minutes)",
                columnType: "int",
            },
            {
                key: "time_registration_notes",
                label: "Time Registration Notes",
            },
            {
                key: "time_registration_person_email",
                label: "Time Registration Person (e-mail)",
                validations: [
                    {
                        validate: "regex",
                        regex: "^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|'(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*')@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])$",
                        errorMessage: "The input is invalid. Please enter a valid email address, e.g. max@company.com.",
                    },
                ],
            },
            {
                key: "time_registration_date_yyyymmdd",
                label: "Time Registration Date (YYYY-MM-DD)",
                columnType: "date",
                outputFormat: "YYYY-MM-DD",
            },
        ],
    };
}
export function settingsForNewProjectsAndTasks(clientOptions, rateCardOptions) {
    return {
        developerMode: true,
        title: "Import new projects, tasks and time entries.",
        completeImportAction: "block",
        identifier: "new_projects",
        modal: false,
        allowCustomOptions: true,
        columns: [
            {
                key: "project_name",
                label: "Project Name",
                validations: [
                    {
                        validate: "required_with",
                        columns: [
                            "project_description",
                            "project_status_planningrunningdone",
                            "project_budget_type_fixedprice_tm_retainer_nonbillable",
                            "project_client",
                            "project_rate_card",
                            "project_start_date_yyyymmdd",
                            "project_end_date_yyyymmdd",
                        ],
                    },
                ],
            },
            {
                key: "project_description",
                label: "Project Description",
            },
            {
                label: "Project Status (PLANNING/RUNNING/DONE)",
                key: "project_status_planningrunningdone",
                validations: [{ validate: "required" }],
                columnType: "category",
                dropdownOptions: [
                    {
                        label: "PLANNING",
                        value: "PLANNING",
                        type: "string",
                    },
                    {
                        label: "RUNNING",
                        value: "RUNNING",
                        type: "string",
                    },
                    {
                        label: "DONE",
                        value: "DONE",
                        type: "string",
                    },
                ],
            },
            {
                label: "Project Budget Type (FIXED_PRICE, TM, RETAINER, NON_BILLABLE)",
                key: "project_budget_type_fixedprice_tm_retainer_nonbillable",
                validations: [{ validate: "required" }],
                columnType: "category",
                dropdownOptions: [
                    {
                        label: "FIXED_PRICE",
                        value: "FIXED_PRICE",
                        type: "string",
                    },
                    {
                        label: "TM",
                        value: "TM",
                        type: "string",
                    },
                    {
                        label: "NON_BILLABLE",
                        value: "NON_BILLABLE",
                        type: "string",
                    },
                    {
                        label: "RETAINER",
                        value: "RETAINER",
                        type: "string",
                    },
                ],
            },
            {
                label: "Project Client",
                key: "project_client",
                validations: [],
                columnType: "category",
                dropdownOptions: clientOptions,
            },
            {
                key: "project_rate_card",
                label: "Project Rate Card",
                validations: [],
                columnType: "category",
                dropdownOptions: rateCardOptions,
            },
            {
                key: "project_start_date_yyyymmdd",
                label: "Project Start Date (YYYY-MM-DD)",
                columnType: "date",
                outputFormat: "YYYY-MM-DD",
            },
            {
                key: "project_end_date_yyyymmdd",
                label: "Project End Date (YYYY-MM-DD)",
                columnType: "date",
                outputFormat: "YYYY-MM-DD",
            },
            ...newPhaseTaskAndTimeColumns,
        ],
    };
}
export function settingsForNewPhasesTasksAndTimeRegsOnExistingProjects() {
    return {
        developerMode: true,
        title: "Import new phases, tasks or time entries into existing projects.",
        completeImportAction: "block",
        identifier: "new_project_content",
        modal: false,
        allowCustomOptions: true,
        columns: [
            {
                key: "existing_project_id",
                label: "Existing Project ID (e.g. P17)",
            },
            ...newPhaseTaskAndTimeColumns,
        ],
    };
}
