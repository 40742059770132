import React, {useEffect, useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {FormattedMessage} from 'react-intl';
import * as tracking from '../../../../tracking';
import {withSocketHandling} from '../../../../socket/withSocketHandling';
import MyWorkProfile from './MyWorkProfile';
import MyWorkWeekStats from './MyWorkWeekStats';
import MyWorkTimeEntries from './MyWorkTimeEntries';
import {MyWorkStatHeader, MyWorkStatsWrapper, MyWorkStatWrapper} from '../MyWork.styled';
import CustomScrollDiv from '../../../shared/components/scroll-bars/custom_scroll_div';
import Util from '../../../shared/util/util';
import {getStatsSocketConfig} from '../MyWorkSocketHandling';
import {EVENT_ID, subscribe, unsubscribe} from '../../../../containers/event_manager';
import {CaretIcon} from 'web-components';
import Moment from 'moment';
import {hasPermission, isClientUser} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {HIDDEN_FEATURES} from '../../../../constants';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';

const MyWorkStatsSection = ({retry, selectedPersonId, viewer, startDate, endDate, allocationData, setSocketConfig}) => {
	const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
	const isClient = isClientUser();
	const [weekArray, setWeekArray] = useState([]);
	const [showWeekStats, setShowWeekStats] = useState(
		Util.localStorageGetItem('my-work-show-week-stats')
			? JSON.parse(Util.localStorageGetItem('my-work-show-week-stats'))
			: true
	);

	const [showTimeStats, setShowTimeStats] = useState(
		Util.localStorageGetItem('my-work-show-time-stats')
			? JSON.parse(Util.localStorageGetItem('my-work-show-time-stats'))
			: true
	);

	const refetchStats = props => {
		if (
			props.duplicateTask ||
			(props.input && (props.input.deadlineYear || props.input.startYear || props.input.assignedPersons))
		) {
			retry();
		}
	};

	useEffect(() => {
		subscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, refetchStats);
		return () => {
			unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, refetchStats);
		};
	}, []);

	useEffect(() => {
		const selectedPersonIdInt = parseInt(atob(selectedPersonId).replace('Person:', ''));
		setSocketConfig(getStatsSocketConfig(selectedPersonIdInt));
	}, [selectedPersonId]);

	const buildWeekArr = (startDate, limit) => {
		const weekArray = [];
		const rec = (date, acc, limit) => {
			if (limit > 0) {
				acc.push(date);
				rec(date.clone().add(1, 'days'), acc, limit - 1);
			}
			return acc;
		};
		return rec(startDate.clone(), weekArray, limit);
	};

	const toggleWeekStats = () => {
		tracking.trackPageAction('Week stats expanded', {expanded: !showWeekStats});
		trackEvent('Week Stats', 'Expansion Toggled', {expanded: !showWeekStats});
		Util.localStorageSetItem('my-work-show-week-stats', !showWeekStats);
		setShowWeekStats(!showWeekStats);
	};

	const toggleTimeStats = () => {
		tracking.trackPageAction('Time stats expanded', {expanded: !showTimeStats});
		trackEvent('Time Stats', 'Expansion Toggled', {expanded: !showWeekStats});
		Util.localStorageSetItem('my-work-show-time-stats', !showTimeStats);
		setShowTimeStats(!showTimeStats);
	};

	useEffect(() => {
		// In order to avoid building weekArray on every render, it is stored in state and only rebuilt when currentViewingDate or timePeriod changes.
		const tempWeekArray = buildWeekArr(startDate, 7);
		if (tempWeekArray !== weekArray) {
			setWeekArray(tempWeekArray);
		}
	}, [startDate]);

	const deadlineTasks = viewer.company.deadlineThisWeekTasks?.edges.filter(task => task.node.fullAccessToProject) || [];
	const isCurrentWeek = Moment().week() === startDate.week();
	return (
		<CustomScrollDiv className="custom-scrollbar-div">
			<MyWorkStatsWrapper>
				<MyWorkStatWrapper data-userpilot={'profile-wrapper'}>
					<MyWorkProfile isAdmin={isAdmin} person={viewer.company.person} actualPersonId={viewer.actualPersonId} />
				</MyWorkStatWrapper>
				{isClient || !isCurrentWeek ? null : (
					<>
						<MyWorkStatWrapper data-userpilot={'stats-wrapper'}>
							<MyWorkStatHeader onClick={toggleWeekStats.bind(this)} data-userpilot={'my-work-statistics-caret'}>
								<h5>
									<FormattedMessage id="common.statistics" />
								</h5>
								<CaretIcon
									color="#535353"
									direction={showWeekStats ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN}
								/>
							</MyWorkStatHeader>
							{showWeekStats ? (
								<MyWorkWeekStats
									startDate={startDate}
									endDate={endDate}
									deadlineTasks={deadlineTasks}
									allocationData={allocationData}
									timeRegistrations={viewer.timeRegistrations.edges}
								/>
							) : null}
						</MyWorkStatWrapper>

						{!Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) && (
							<MyWorkStatWrapper data-userpilot={'time-entries-wrapper'} hideBorder={true}>
								<MyWorkStatHeader onClick={toggleTimeStats.bind(this)}>
									<h5>
										<FormattedMessage id="common.time_entries" />
									</h5>
									<CaretIcon
										color="#535353"
										direction={showTimeStats ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN}
									/>
								</MyWorkStatHeader>
								{showTimeStats ? (
									<MyWorkTimeEntries
										person={viewer.company.person}
										timeRegistrations={viewer.timeRegistrations.edges}
										weekArray={weekArray}
									/>
								) : null}
							</MyWorkStatWrapper>
						)}
					</>
				)}
			</MyWorkStatsWrapper>
		</CustomScrollDiv>
	);
};

const MyWorkStatsSectionQuery = graphql`
	query MyWorkStatsSection_Query(
		$personId: ID
		$searchQuery: TaskSearchQueryType
		$startDateString: String
		$endDateString: String
	) {
		viewer {
			actualPersonId
			component(name: "my_work_stats_section")
			...MyWorkStatsSection_viewer
				@arguments(
					personId: $personId
					searchQuery: $searchQuery
					startDateString: $startDateString
					endDateString: $endDateString
				)
		}
	}
`;

export {MyWorkStatsSectionQuery};

export default withSocketHandling(
	createFragmentContainer(MyWorkStatsSection, {
		viewer: graphql`
			fragment MyWorkStatsSection_viewer on Viewer
			@argumentDefinitions(
				personId: {type: "ID"}
				searchQuery: {type: "TaskSearchQueryType"}
				startDateString: {type: "String"}
				endDateString: {type: "String"}
			) {
				id
				actualPersonId
				availableFeatureFlags {
					key
				}
				company {
					id
					idleTimes {
						edges {
							node {
								id
								name
							}
						}
					}
					person(id: $personId) {
						monday
						tuesday
						wednesday
						thursday
						friday
						saturday
						sunday
						createdAt
						holidayCalendar {
							id
							holidayCalendarEntries(first: 10000) {
								edges {
									node {
										id
										day
										month
										year
										name
									}
								}
							}
							name
						}
						...MyWorkProfile_person
					}
					deadlineThisWeekTasks: allTasks(
						first: 1000000
						searchQuery: $searchQuery
						approvedOnly: true
						runningProjectOnly: true
					) @connection(key: "Company_deadlineThisWeekTasks", filters: []) {
						edges {
							node {
								id
								fullAccessToProject
								estimateForecast
								timeLeft
								statusColumnV2 {
									id
									name
									category
								}
								timeRegistrations(first: 1000000) @connection(key: "Task_timeRegistrations") {
									edges {
										node {
											id
											minutesRegistered
											day
											month
											year
										}
									}
								}
							}
						}
					}
				}
				timeRegistrations(
					first: 100000
					personId: $personId
					startDate: $startDateString
					endDate: $endDateString
					verifyPermissionRights: false
				) @connection(key: "Viewer_timeRegistrations", filters: []) {
					edges {
						node {
							id
							day
							month
							year
							minutesRegistered
							task {
								id
								deadlineDay
								deadlineMonth
								deadlineYear
							}
							project {
								id
							}
						}
					}
				}
			}
		`,
	})
);
