import React, { useMemo, useState, useEffect } from 'react';
import { SelectionList, Modal, FlexRow, Text } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { Size } from '@forecast-it/design-system/dist/forecast-v2/components/feedback/modal/Modal';
import styled from 'styled-components';
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const SelectionListContainer = styled(SelectionList.RowContainer) `
	height: 490px;
`;
const MAX_ALLOWED_LEVELS = 3;
const treeHeight = (options) => {
    var _a;
    return (_a = options === null || options === void 0 ? void 0 : options.reduce(function (maxHeight, option) {
        return Math.max(maxHeight, treeHeight(option.nestedOptions) + 1);
    }, 1)) !== null && _a !== void 0 ? _a : 0;
};
const ShowHideColumnsModal = ({ eyeOptions: originalEyeOptions, onEyeOptionsChange, closeModal, }) => {
    const { formatMessage } = useIntl();
    const [eyeOptions, setEyeOptions] = useState(originalEyeOptions);
    const [optionLevels, setOptionLevels] = useState([eyeOptions]);
    const maxLevel = useMemo(() => Math.min(treeHeight(originalEyeOptions), MAX_ALLOWED_LEVELS), originalEyeOptions);
    const [openedOptions, setOpenedOptions] = useState(['', '']);
    useEffect(() => {
        if (maxLevel === 2) {
            setOptionLevels([eyeOptions, []]);
        }
        if (maxLevel === 3) {
            setOptionLevels([eyeOptions, [], []]);
        }
    }, [maxLevel]);
    const toggleNestedOptions = (option, checkedStatus) => {
        option.nestedOptions.forEach(nestedOption => {
            nestedOption.checked = !checkedStatus;
            if (nestedOption.nestedOptions) {
                toggleNestedOptions(nestedOption, checkedStatus);
            }
        });
    };
    const toggleOptions = (eyeOption) => {
        if (eyeOption) {
            if (eyeOption.nestedOptions) {
                toggleNestedOptions(eyeOption, eyeOption.checked);
            }
            eyeOption.checked = !eyeOption.checked;
        }
    };
    const toggleParentOption = (option, nestedOptions) => {
        if (option && (nestedOptions === null || nestedOptions === void 0 ? void 0 : nestedOptions.every(nestedOption => nestedOption.checked === false))) {
            option.checked = false;
        }
        else if (option && (nestedOptions === null || nestedOptions === void 0 ? void 0 : nestedOptions.every(nestedOption => nestedOption.checked === true))) {
            option.checked = true;
        }
    };
    const findOption = (options, key) => {
        return options.find(option => option.name === key);
    };
    const toggleChecked = (key, level) => {
        setEyeOptions(prevEyeOptions => {
            if (level === 0) {
                const eyeOption = findOption(prevEyeOptions, key);
                eyeOption && toggleOptions(eyeOption);
            }
            else if (level === 1) {
                const option = findOption(prevEyeOptions, openedOptions[0]);
                const nestedOptions = option === null || option === void 0 ? void 0 : option.nestedOptions;
                const eyeOption = nestedOptions && findOption(nestedOptions, key);
                eyeOption && toggleOptions(eyeOption);
                option && nestedOptions && toggleParentOption(option, nestedOptions);
            }
            else if (level === 2) {
                const option = findOption(prevEyeOptions, openedOptions[0]);
                const firstNestedOptions = option === null || option === void 0 ? void 0 : option.nestedOptions;
                const firstNestedOption = firstNestedOptions && findOption(firstNestedOptions, openedOptions[1]);
                const secondNestedOptions = firstNestedOption === null || firstNestedOption === void 0 ? void 0 : firstNestedOption.nestedOptions;
                const eyeOption = secondNestedOptions && findOption(secondNestedOptions, key);
                eyeOption && toggleOptions(eyeOption);
                firstNestedOption && secondNestedOptions && toggleParentOption(firstNestedOption, secondNestedOptions);
                option && firstNestedOptions && toggleParentOption(option, firstNestedOptions);
            }
            return [...prevEyeOptions];
        });
    };
    const confirm = () => {
        onEyeOptionsChange(eyeOptions);
        closeModal();
    };
    const size = maxLevel === 1 ? Size.EXTRA_SMALL : maxLevel === 2 ? Size.MEDIUM : Size.EXTRA_LARGE;
    const toggleOpened = (key, level) => {
        var _a;
        if (level === 0) {
            const eyeOption = findOption(eyeOptions, key);
            if (eyeOption && eyeOption.nestedOptions) {
                setOptionLevels(prevOptionLevels => {
                    const newOptionLevels = [...prevOptionLevels];
                    newOptionLevels[1] = openedOptions[0] === eyeOption.name ? [] : eyeOption.nestedOptions;
                    if (newOptionLevels.length === 3) {
                        newOptionLevels[2] = [];
                    }
                    return newOptionLevels;
                });
                setOpenedOptions(prevOpenedOptions => {
                    const newOpenedOptions = [...prevOpenedOptions];
                    newOpenedOptions[0] = newOpenedOptions[0] === eyeOption.name ? '' : eyeOption.name;
                    newOpenedOptions[1] = '';
                    return newOpenedOptions;
                });
            }
        }
        else if (level === 1) {
            const nestedOptions = (_a = findOption(eyeOptions, openedOptions[0])) === null || _a === void 0 ? void 0 : _a.nestedOptions;
            const eyeOption = nestedOptions && findOption(nestedOptions, key);
            if (eyeOption && eyeOption.nestedOptions) {
                setOptionLevels(prevOptionLevels => {
                    const newOptionLevels = [...prevOptionLevels];
                    newOptionLevels[2] = openedOptions[1] === eyeOption.name ? [] : eyeOption.nestedOptions;
                    return newOptionLevels;
                });
                setOpenedOptions(prevOpenedOptions => {
                    const newOpenedOptions = [...prevOpenedOptions];
                    newOpenedOptions[1] = newOpenedOptions[1] === eyeOption.name ? '' : eyeOption.name;
                    return newOpenedOptions;
                });
            }
        }
    };
    return (React.createElement(Modal, { size: size, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: "change_view.show_hide_columns" })),
        React.createElement(Modal.Content, null,
            React.createElement(FormattedMessage, { id: "change_view.show_hide_columns.description" }),
            React.createElement(SelectionListContainer, null, optionLevels.map((options, level) => (React.createElement(SelectionList, { key: level, "aria-label": 'Eye Options - Level ' + level, openedOptions: openedOptions }, options.map(({ name, translationId, checked, nestedOptions }) => {
                const label = formatMessage({ id: translationId });
                return (React.createElement(SelectionList.Item, { key: name, textValue: label, "data-cy": name + '-item' },
                    React.createElement(FlexRow, { justifyContent: 'space-between' },
                        React.createElement(Text, { color: checked ? 'main' : 'auxiliary' }, label),
                        React.createElement(FlexRow, { justifyContent: 'flex-end' },
                            React.createElement(SelectionList.ShowHideIcon, { onClick: () => toggleChecked(name, level), checked: checked, "data-cy": name }),
                            nestedOptions && (React.createElement(SelectionList.OpenNestedOptionIcon, { onClick: () => toggleOpened(name, level), "data-cy": name + '-open' }))))));
            })))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: confirm },
                React.createElement(FormattedMessage, { id: 'common.confirm' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: 'common.close' })))));
};
export default ShowHideColumnsModal;
