import Util from "../../shared/util/util";
import ImportMode from "./ImportMode";
export default class DataImportValidator {
    constructor({ nuvoImporter, importMode, projects, tasks, emails, companyIdBase64 }) {
        this.nuvoImporter = nuvoImporter;
        this.importMode = importMode;
        this.projects = projects;
        this.tasks = tasks;
        this.emails = emails;
        this.companyIdBase64 = companyIdBase64;
    }
    registerValidationForExistingTasksAndProjects() {
        this.nuvoImporter.registerColumnHooks("existing_project_id_or_task_id", (hookedRecordValues) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(hookedRecordValues.map(([cellValue, rowIndex]) => {
                        const validationResult = this.validateExistingTaskOrProjectId(cellValue);
                        return [
                            {
                                value: cellValue,
                                info: validationResult
                            },
                            rowIndex
                        ];
                    }));
                }, 0);
            });
        });
    }
    registerColumnHooksTaskEmails() {
        this.nuvoImporter.registerColumnHooks("task_owner_email", (hookedRecordValues) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(hookedRecordValues.map(([cellValue, rowIndex]) => {
                        if (cellValue) {
                            return [
                                {
                                    value: cellValue,
                                    info: this.validateSingleEmail(cellValue)
                                },
                                rowIndex
                            ];
                        }
                        else {
                            return [
                                {
                                    value: "",
                                    info: undefined
                                },
                                rowIndex
                            ];
                        }
                    }));
                }, 0);
            });
        });
        this.nuvoImporter.registerColumnHooks("task_assigned_comma_seperated_this.emails", (hookedRecordValues) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(hookedRecordValues.map(([cellValue, rowIndex]) => {
                        return [
                            {
                                value: cellValue ? cellValue === null || cellValue === void 0 ? void 0 : cellValue.toLowerCase() : "",
                                info: this.validateEmailCommaSeparatedList(cellValue)
                            },
                            rowIndex
                        ];
                    }));
                }, 0);
            });
        });
    }
    registerColumnHooksForExistingProjects() {
        this.nuvoImporter.registerColumnHooks("existing_project_id", (hookedRecordValues) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(hookedRecordValues.map(([cellValue, rowIndex]) => {
                        return [
                            {
                                value: cellValue === null || cellValue === void 0 ? void 0 : cellValue.existing_project_id,
                                info: this.validateProjectId(cellValue)
                            },
                            rowIndex
                        ];
                    }));
                }, 0);
            });
        });
    }
    registerColumnHooksForTimeRegEmails() {
        this.nuvoImporter.registerColumnHooks("time_registration_person_email", (hookedRecordValues) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(hookedRecordValues.map(([cellValue, rowIndex]) => {
                        if (cellValue) {
                            return [
                                {
                                    value: cellValue.toLowerCase(),
                                    info: this.validateSingleEmail(cellValue)
                                },
                                rowIndex
                            ];
                        }
                        else {
                            return [
                                {
                                    value: "",
                                    info: undefined
                                },
                                rowIndex
                            ];
                        }
                    }));
                }, 0);
            });
        });
    }
    validateExistingTaskOrProjectId(cellValue) {
        let validationResult;
        if (!cellValue ||
            (cellValue.substring(0, 1) !== "P" && cellValue.substring(0, 1) !== "T")) {
            validationResult = [
                {
                    level: "error",
                    message: "Incorrect format. Must be a task id that starts with a T or a project id that starts with a P (e.g. P17 or T324)."
                }
            ];
        }
        else if (cellValue.substring(0, 1) === "T" &&
            !this.tasks.includes(cellValue)) {
            validationResult = [
                {
                    level: "error",
                    message: "Could not be matched to any existing task in your account."
                }
            ];
        }
        else if (cellValue.substring(0, 1) === "P" &&
            !this.projects.includes(cellValue)) {
            validationResult = [
                {
                    level: "error",
                    message: "Could not be matched to any existing project in your account."
                }
            ];
        }
        console.log("IMPORTER validateExistingTaskOrProjectId " +
            cellValue +
            " " +
            JSON.stringify(validationResult));
        return validationResult;
    }
    registerEntryChange() {
        this.nuvoImporter.registerEntryChange((row) => {
            return this.validateRowChanged(row);
        });
    }
    getEntryChangeForResult(_value, validationResult) {
        return {
            value: undefined,
            info: validationResult
        };
    }
    validateRowChanged(row) {
        return {
            existing_project_id_or_task_id: this.importMode === ImportMode.ImportTimeRegs &&
                this.getEntryChangeForResult(row === null || row === void 0 ? void 0 : row.existing_project_id_or_task_id, this.validateExistingTaskOrProjectId(row === null || row === void 0 ? void 0 : row.existing_project_id_or_task_id)),
            existing_project_id: this.importMode === ImportMode.ImportPhasesTasksTimeRegs &&
                this.getEntryChangeForResult(row === null || row === void 0 ? void 0 : row.existing_project_id, this.validateProjectId(row === null || row === void 0 ? void 0 : row.existing_project_id)),
            time_registration_person_email: this.getEntryChangeForResult(row === null || row === void 0 ? void 0 : row.time_registration_person_email, this.validateSingleEmail(row === null || row === void 0 ? void 0 : row.time_registration_person_email)),
            task_owner_email: this.importMode !== ImportMode.ImportTimeRegs &&
                this.getEntryChangeForResult(row === null || row === void 0 ? void 0 : row.task_owner_email, this.validateSingleEmail(row === null || row === void 0 ? void 0 : row.task_owner_email)),
            task_assigned_comma_seperated_emails: this.importMode !== ImportMode.ImportTimeRegs &&
                this.getEntryChangeForResult(row === null || row === void 0 ? void 0 : row.task_assigned_comma_seperated_emails, this.validateEmailCommaSeparatedList(row === null || row === void 0 ? void 0 : row.task_assigned_comma_seperated_emails))
        };
    }
    validateProjectId(projectId) {
        let validationResult = undefined;
        if (!projectId || projectId.substring(0, 1) !== "P") {
            validationResult = [
                {
                    level: "error",
                    message: "Incorrect format. Must be a project id that starts with a P (e.g. P17)."
                }
            ];
        }
        else if (projectId.substring(0, 1) === "P" &&
            !this.projects.includes(projectId)) {
            validationResult = [
                {
                    level: "error",
                    message: "Could not be matched to any existing project in your account."
                }
            ];
        }
        return validationResult;
    }
    validateEmailCommaSeparatedList(cellValue) {
        const companyId = Util.getIdFromBase64String(this.companyIdBase64);
        let validationResult;
        if (cellValue === undefined || cellValue === null || cellValue === "") {
            validationResult = [];
        }
        else {
            cellValue
                .replaceAll(" ", "")
                .split(",")
                .forEach((r) => {
                if (!this.emails.includes(r.toLowerCase()) &&
                    !this.emails.includes(companyId + "_" + r.toLowerCase())) {
                    validationResult = [
                        {
                            level: "info",
                            message: "This email does not exist on your account and will be created as a deactivated user."
                        }
                    ];
                    const emailReg = /^\S+@\S+\.\S+$/;
                    const isEmailValid = emailReg.test(r);
                    if (!isEmailValid) {
                        validationResult = [
                            {
                                level: "error",
                                message: "The email is invalid. Please enter a valid email address, error.g. max@company.com."
                            }
                        ];
                    }
                }
            });
        }
        return validationResult;
    }
    validateSingleEmail(cellValue) {
        const companyId = Util.getIdFromBase64String(this.companyIdBase64);
        let info;
        const emailReg = /^\S+@\S+\.\S+$/;
        const isEmailValid = emailReg.test(cellValue);
        if (isEmailValid &&
            !this.emails.includes(cellValue.toLowerCase()) &&
            !this.emails.includes(companyId + "_" + cellValue.toLowerCase())) {
            info = [
                {
                    level: "info",
                    message: "This email does not exist on your account and will be created as a deactivated user."
                }
            ];
        }
        return info;
    }
}
