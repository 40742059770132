import {Checkbox, Table} from 'web-components';
import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import Styled from 'styled-components';
import ActionsMenu from '../../shared/components/action-menu/actions_menu';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import tracking from '../../../tracking';
import Util from '../../shared/util/util';
import DeleteCustomFieldDefinitionMutation from '../../../mutations/delete_custom_field_definition_mutation.js';
import {createToast} from '../../shared/components/toasts/toast';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {MODULE_TYPES} from '../../../constants';
import {hasModule} from '../../shared/util/ModuleUtil';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';

export const ActionMenuWrapper = Styled(ActionsMenu)`
	:not(.expanded)  {
	    visibility: hidden;
	}
`;

const CustomFieldDefinitionRow = ({company, tableColumnsProps, customFieldDefinition}) => {
	const {formatMessage} = useIntl();

	const openCustomFieldDefinition = () => {
		tracking.trackEvent('CustomFieldDefinition - Opened');
		trackEvent('Custom Field Definition', 'Opened');
		showModal({
			type: MODAL_TYPE.CUSTOM_FIELD_DEFINITION,
			customFieldDefinitionId: customFieldDefinition.id,
			entityType: customFieldDefinition.entityType,
		});
	};

	const deleteCustomFieldDefinition = () => {
		Util.CommitMutation(
			DeleteCustomFieldDefinitionMutation,
			{
				id: customFieldDefinition.id,
				companyId: company.id,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage(
						{id: 'settings.custom-fields.delete_toast'},
						{name: customFieldDefinition.displayName}
					),
				});
			}
		);
	};
	const confirmDeletion = () => {
		tracking.trackEvent('CustomFieldDefinition - Delete confirm modal opened');
		trackEvent('Custom Field Confirm Delete Modal', 'Opened');
		showModal({
			type: MODAL_TYPE.CONFIRM_SKILL_CATEGORY_DELETION,
			title: formatMessage(
				{id: 'settings.custom-fields.delete_title'},
				{
					name: customFieldDefinition.displayName,
					entityType: formatMessage({
						id: 'settings.custom-fields.entity-type.' + customFieldDefinition.entityType,
					}),
				}
			),
			warningMessage: formatMessage({id: 'settings.custom-fields.delete_warning'}),
			confirmText: formatMessage({id: 'settings.confirm'}),
			onDelete: deleteCustomFieldDefinition,
		});
	};

	const actionsMenuOptions = [
		{
			text: formatMessage({id: 'common.delete'}),
			cy: 'delete',
			onClick: confirmDeletion,
		},
	];

	return (
		<>
			<Table.Row
				hoverStyle={{visibility: 'visible'}}
				cy={`custom-field-definition-row-${customFieldDefinition.key}`}
				onClick={openCustomFieldDefinition}
				{...tableColumnsProps}
			>
				<Table.Column>{customFieldDefinition.key}</Table.Column>
				<Table.Column>{customFieldDefinition.displayName}</Table.Column>
				<Table.Column>
					<Checkbox checked={customFieldDefinition.readOnly} disabled={true} />
				</Table.Column>
				{hasModule(MODULE_TYPES.SISENSE) &&
				(customFieldDefinition.entityType === 'PROJECT' || customFieldDefinition.entityType === 'TASK') &&
				hasFeatureFlag('custom_field_ava_refs') ? (
					<Table.Column>
						{customFieldDefinition.avaRef
							? `${
									String(customFieldDefinition.entityType).charAt(0) +
									String(customFieldDefinition.entityType.toLowerCase()).slice(1)
							  } Custom Field ${customFieldDefinition.avaRef}`
							: 'None'}
					</Table.Column>
				) : null}
				<Table.Column>
					<ActionMenuWrapper
						cy={customFieldDefinition.key}
						className={'row-hover'}
						whiteInner={true}
						options={actionsMenuOptions}
					/>
				</Table.Column>
			</Table.Row>
		</>
	);
};

export default createFragmentContainer(CustomFieldDefinitionRow, {
	company: graphql`
		fragment CustomFieldDefinitionRow_company on Company {
			id
		}
	`,
	customFieldDefinition: graphql`
		fragment CustomFieldDefinitionRow_customFieldDefinition on CustomFieldDefinition {
			id
			key
			displayName
			readOnly
			entityType
			avaRef
		}
	`,
});
