import React, {useEffect, useState} from 'react';
import {BasicNumberInput, Button, Checkbox, CopyIcon, Dropdown, EyeIcon, RadioButton, ReloadIcon, Switch} from 'web-components';
import ClipboardButton from 'react-clipboard.js';
import {BUTTON_COLOR, BUTTON_STYLE, ESTIMATION_UNIT} from '../../../../constants';
import {
	CheckBoxDescription,
	CopyLinkDropdownWrapper,
	CopyLinkWrapper,
	ExtraElementWrapper,
	HoursInputWrapper,
	LinkBox,
	PasswordBullet,
	PasswordWrapper,
	RadioWithDescriptionWrapper,
	SectionDescription,
	SelectionElementLabel,
	SelectionElementLineWrapper,
	Separator,
	SprintInputWrapper,
	SprintLenghtWrapper,
	StoryPointLable,
	SubSection,
} from './ProjectSettings_styled';
import {
	changeEstimationUnit,
	changeHoursPrStoryPoint,
	changePublicCreateTaskPassword,
	changeRamainingCalculations,
	changeSprintLenght,
	changeTaskMonitoring,
	changeTaskSubLevel,
	changeUsePublicCreateTask,
	changeUsePublicCreateTaskPassword,
	changeUseSprint,
	changeUseTaskHierarchy,
	handleChangePublicCreateTaskPageStatusColumn,
	changeUseDeliverables,
} from './ProjectSettingsMutations';
import {createToast} from '../../../shared/components/toasts/toast';
import HoursInput from '../../../shared/components/inputs/hours-input/hours_input_view';
import ForecastTooltip from '../../../shared/components/tooltips/ForecastTooltip';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import DirectApi from '../../../../directApi';
import {FormattedMessage} from 'react-intl';

export const TASK_MONITORING = {
	NO_TASK_MONITORING: 'no_monitoring',
	USE_FOLLOWERS: 'followers',
	USE_OWNER: 'owner',
};

const ProjectSettingsTasks = ({
	intl,
	projectId,
	useSprint,
	sprintLength,
	taskSubLevel,
	taskMonitoring,
	estimationUnit,
	hoursPrStoryPoint,
	remainingAutoCalculated,
	linkToPublicCreateTaskPage,
	publicCreateTaskPageStatusColumn,
	projectColumns,
	projectDoneOrHalted,
	isJiraProject,
	isADOProject,
	noTimeRegProject,
	isInProjectGroup,
	useTaskHierarchy,
	useDeliverables,
	compatibleWithDeliverables,
	usePublicCreateTaskPassword,
	publicCreateTaskPassword,
	jiraSyncRemaining,
}) => {
	const {formatMessage} = intl;
	const [_sprintLength, setSprintLength] = useState(sprintLength || 14);
	const [_hoursPrStoryPoint, setHoursPrStoryPoint] = useState(hoursPrStoryPoint);
	const [_password, setPassword] = useState(publicCreateTaskPassword);
	const [_showPassword, setShowPassword] = useState(false);

	const publicTaskLink = `https://app.forecast.it/create-task-form/${linkToPublicCreateTaskPage}?dc=${DirectApi.getDatacenter()}`;

	const usingSubtaskIntegration = isJiraProject || isADOProject;

	const handleCchangeSprintLenght = value => {
		const roundedValue = Math.round(value);
		setSprintLength(roundedValue);
		changeSprintLenght(projectId, roundedValue);
	};

	const handleChangeHoursPrStoryPoint = (hours, _) => {
		const minutes = hours * 60;
		setHoursPrStoryPoint(hours);
		if (!isNaN(minutes)) {
			changeHoursPrStoryPoint(projectId, minutes);
		}
	};

	const generatePassword = () => {
		const length = 32;
		const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
		let password = '';
		for (var i = 0, n = charset.length; i < length; ++i) {
			password += charset.charAt(Math.floor(Math.random() * n));
		}
		changePublicCreateTaskPassword(projectId, password);
	};

	const getPassword = () => {
		if (!_password) return null;

		if (_showPassword) {
			return _password;
		} else {
			let bullets = [];
			for (let i = 0; i < _password.length; i++) {
				bullets.push(<PasswordBullet />);
			}
			return bullets;
		}
	};

	const handleChangeUsePublicCreateTaskPassword = () => {
		changeUsePublicCreateTaskPassword(projectId, usePublicCreateTaskPassword);

		if (!usePublicCreateTaskPassword && !publicCreateTaskPassword) {
			generatePassword();
		}
	};

	const showConvertOldSubtasksModal = () => {
		showModal({
			type: MODAL_TYPE.CONVERT_OLD_SUBTASKS,
			projectId,
		});
	};

	const showDisableTaskHierarchyWarning = () => {
		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'settings.task.confirm_disable_task_hierarchy_header',
			warningInformation: [
				<div>
					<FormattedMessage id="settings.task.confirm_disable_task_hierarchy" />
				</div>,
			],
			irreversible: true,
			buttons: [
				{
					text: <FormattedMessage id="common.cancel" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: <FormattedMessage id="common.confirm" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: () => changeUseTaskHierarchy(projectId, !useTaskHierarchy),
				},
			],
		});
	};

	const toggleTaskHierarchy = () => {
		// If old subtasks are enabled, show conversion modal
		if (!usingSubtaskIntegration && taskSubLevel === 2 && !useTaskHierarchy) {
			showConvertOldSubtasksModal();
		} else {
			if (useTaskHierarchy) {
				showDisableTaskHierarchyWarning();
			} else {
				changeUseTaskHierarchy(projectId, !useTaskHierarchy);
			}
		}
	};

	useEffect(() => {
		setPassword(publicCreateTaskPassword);
	}, [publicCreateTaskPassword]);

	const showDeliverablesSetting = hasFeatureFlag('deliverables') && compatibleWithDeliverables;

	return (
		<>
			{/** Deliverables */}
			{showDeliverablesSetting && (
				<SubSection
					title={formatMessage({id: 'common.deliverables'})}
					description={formatMessage({id: 'project_settings.deliverables_description'})}
					showNew={true}
				>
					<SelectionElementLineWrapper>
						<Switch checked={useDeliverables} onChange={value => changeUseDeliverables(projectId, value)} />
						<SelectionElementLabel>{formatMessage({id: 'common.enable'})}</SelectionElementLabel>
					</SelectionElementLineWrapper>
				</SubSection>
			)}
			{/** Sprint planning */}
			{!isInProjectGroup && (
				<SubSection
					title={formatMessage({id: 'project_settings.sprint_planning'})}
					description={formatMessage({id: 'project_settings.sprint_planning_desc'})}
				>
					<SelectionElementLineWrapper>
						<ForecastTooltip
							content={isJiraProject && formatMessage({id: 'project_settings.blocked_by_jira_connection'})}
						>
							<Switch
								locked={projectDoneOrHalted || isJiraProject}
								checked={useSprint}
								onChange={value => changeUseSprint(projectId, value)}
							/>
						</ForecastTooltip>
						<SelectionElementLabel>{formatMessage({id: 'common.enable'})}</SelectionElementLabel>
					</SelectionElementLineWrapper>
					{useSprint && (
						<SelectionElementLineWrapper>
							<SprintLenghtWrapper>
								{formatMessage({id: 'project_settings.on_sprint_is'})}
								<SprintInputWrapper>
									<BasicNumberInput
										value={_sprintLength}
										callback={days => handleCchangeSprintLenght(days)}
										width={'50px'}
										disabled={!useSprint || projectDoneOrHalted || isJiraProject}
										hasError={_sprintLength < 1}
									/>
								</SprintInputWrapper>
								{formatMessage({id: 'common.days'}).toLowerCase()}
							</SprintLenghtWrapper>
						</SelectionElementLineWrapper>
					)}
				</SubSection>
			)}
			{/** Task sub-levels */}
			<SubSection
				title={formatMessage({id: 'common.sub_tasks'})}
				description={formatMessage({id: 'project_settings.sub_tasks_description'})}
			>
				<SelectionElementLineWrapper>
					<Switch
						checked={useTaskHierarchy}
						onChange={() => toggleTaskHierarchy()}
						locked={projectDoneOrHalted || isADOProject}
					/>
					<SelectionElementLabel>{formatMessage({id: 'common.enable'})}</SelectionElementLabel>
				</SelectionElementLineWrapper>
			</SubSection>
			{/** Task sub-levels */}
			{taskSubLevel !== 2 ? (
				''
			) : (
				<SubSection
					title={formatMessage({id: 'project_settings.task_sub_levels'})}
					description={formatMessage(
						{
							id: 'project_settings.task_sub_levels_desc',
						},
						{
							br: <br />,
							b: c => <b>{c}</b>,
						}
					)}
				>
					{!usingSubtaskIntegration ? (
						<Button
							size={Button.SIZE.STANDARD}
							variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
							onClick={showConvertOldSubtasksModal}
						>
							{formatMessage({id: 'settings_labels.move_to_skills_convert'})}
						</Button>
					) : (
						''
					)}

					<RadioWithDescriptionWrapper>
						<SelectionElementLineWrapper>
							<RadioButton
								checked={taskSubLevel === 1}
								onClick={() => changeTaskSubLevel(projectId, 1)}
								disabled={projectDoneOrHalted}
							/>
							<SelectionElementLabel>{formatMessage({id: 'project_settings.to-do-lists'})}</SelectionElementLabel>
						</SelectionElementLineWrapper>
						<CheckBoxDescription>{formatMessage({id: 'project_settings.to-do-lists_desc'})}</CheckBoxDescription>
					</RadioWithDescriptionWrapper>
					<RadioWithDescriptionWrapper>
						<SelectionElementLineWrapper>
							<RadioButton
								checked={taskSubLevel === 2}
								onClick={() => changeTaskSubLevel(projectId, 2)}
								disabled={projectDoneOrHalted}
							/>
							<SelectionElementLabel>{formatMessage({id: 'common.sub_tasks'})}</SelectionElementLabel>
						</SelectionElementLineWrapper>
						<CheckBoxDescription>{formatMessage({id: 'project_settings.sub_task_desc'})}</CheckBoxDescription>
					</RadioWithDescriptionWrapper>
				</SubSection>
			)}
			{/** Task monitoring */}
			<SubSection
				title={formatMessage({id: 'project_settings.task_monitoring_setting'})}
				description={formatMessage({id: 'project_settings.task_monitoring_desc'})}
			>
				<RadioWithDescriptionWrapper>
					<SelectionElementLineWrapper>
						<RadioButton
							checked={taskMonitoring === TASK_MONITORING.USE_OWNER}
							onClick={() => changeTaskMonitoring(projectId, TASK_MONITORING.USE_OWNER)}
							disabled={projectDoneOrHalted}
						/>
						<SelectionElementLabel>
							{formatMessage({id: 'project_settings.task_owner_checkbox'})}
						</SelectionElementLabel>
					</SelectionElementLineWrapper>
					<CheckBoxDescription>
						{formatMessage({id: 'project_settings.task_owner_checkbox_desc'})}
					</CheckBoxDescription>
				</RadioWithDescriptionWrapper>
				<RadioWithDescriptionWrapper>
					<SelectionElementLineWrapper>
						<RadioButton
							checked={taskMonitoring === TASK_MONITORING.USE_FOLLOWERS}
							onClick={() => changeTaskMonitoring(projectId, TASK_MONITORING.USE_FOLLOWERS)}
							disabled={projectDoneOrHalted}
						/>
						<SelectionElementLabel>
							{formatMessage({id: 'project_settings.task_follower_checkbox'})}
						</SelectionElementLabel>
					</SelectionElementLineWrapper>
					<CheckBoxDescription>
						{formatMessage({id: 'project_settings.task_follower_checkbox_desc'})}
					</CheckBoxDescription>
				</RadioWithDescriptionWrapper>
				<RadioWithDescriptionWrapper>
					<SelectionElementLineWrapper>
						<RadioButton
							checked={
								taskMonitoring !== TASK_MONITORING.USE_FOLLOWERS && taskMonitoring !== TASK_MONITORING.USE_OWNER
							}
							onClick={() => changeTaskMonitoring(projectId, TASK_MONITORING.NO_TASK_MONITORING)}
							disabled={projectDoneOrHalted}
						/>
						<SelectionElementLabel>
							{formatMessage({id: 'project_settings.do_not_use_task_monitoring'})}
						</SelectionElementLabel>
					</SelectionElementLineWrapper>
				</RadioWithDescriptionWrapper>
			</SubSection>
			{/** Estimation units */}
			{!isInProjectGroup && (
				<SubSection
					title={formatMessage({id: 'settings.estimation-title'})}
					description={formatMessage({id: 'settings.estimation_units_title'})}
				>
					<SelectionElementLineWrapper>
						<ForecastTooltip
							content={isJiraProject && formatMessage({id: 'project_settings.blocked_by_jira_connection'})}
						>
							<RadioButton
								checked={estimationUnit === ESTIMATION_UNIT.HOURS}
								onClick={() => changeEstimationUnit(projectId, ESTIMATION_UNIT.HOURS)}
								disabled={projectDoneOrHalted || isJiraProject}
							/>
						</ForecastTooltip>
						<SelectionElementLabel>{formatMessage({id: 'common.hours'})}</SelectionElementLabel>
					</SelectionElementLineWrapper>
					<SelectionElementLineWrapper>
						<ForecastTooltip
							content={isJiraProject && formatMessage({id: 'project_settings.blocked_by_jira_connection'})}
						>
							<RadioButton
								checked={estimationUnit === ESTIMATION_UNIT.POINTS}
								onClick={() => changeEstimationUnit(projectId, ESTIMATION_UNIT.POINTS)}
								disabled={projectDoneOrHalted || isJiraProject}
							/>
						</ForecastTooltip>
						<SelectionElementLabel>{formatMessage({id: 'common.points'})}</SelectionElementLabel>
					</SelectionElementLineWrapper>
					{estimationUnit === ESTIMATION_UNIT.POINTS && (
						<ExtraElementWrapper>
							<StoryPointLable>{formatMessage({id: 'settings.estimation-hours-pr-point'})}</StoryPointLable>
							<HoursInputWrapper>
								<HoursInput
									value={_hoursPrStoryPoint}
									mutation={handleChangeHoursPrStoryPoint}
									hasError={isNaN(_hoursPrStoryPoint * 60)}
								></HoursInput>
							</HoursInputWrapper>
						</ExtraElementWrapper>
					)}
				</SubSection>
			)}

			{/** Remaining work estimate */}
			<SubSection
				title={formatMessage({id: 'project_settings.remaining_work_estimation'})}
				description={formatMessage({id: 'project_settings.remaining_work_estimation_desc'})}
			>
				{noTimeRegProject && (
					<SectionDescription>
						{formatMessage({id: 'project_settings.remaining_work_estimate_disabled'})}
					</SectionDescription>
				)}

				<SelectionElementLineWrapper>
					<ForecastTooltip
						content={
							jiraSyncRemaining && formatMessage({id: 'project_settings.remaining_work_estimate_locked_jira'})
						}
					>
						<RadioButton
							checked={!remainingAutoCalculated}
							onClick={() => changeRamainingCalculations(projectId, false)}
							disabled={projectDoneOrHalted || noTimeRegProject || jiraSyncRemaining}
						/>
					</ForecastTooltip>
					<SelectionElementLabel>{formatMessage({id: 'common.manually'})}</SelectionElementLabel>
				</SelectionElementLineWrapper>
				<SelectionElementLineWrapper>
					<ForecastTooltip
						content={
							jiraSyncRemaining && formatMessage({id: 'project_settings.remaining_work_estimate_locked_jira'})
						}
					>
						<RadioButton
							checked={remainingAutoCalculated}
							onClick={() => changeRamainingCalculations(projectId, true)}
							disabled={projectDoneOrHalted || noTimeRegProject || jiraSyncRemaining}
						/>
					</ForecastTooltip>
					<SelectionElementLabel>{formatMessage({id: 'common.automatically'})}</SelectionElementLabel>
				</SelectionElementLineWrapper>
			</SubSection>

			{/** Create new task (external access) */}
			<SubSection
				title={formatMessage({id: 'project_settings.public_create_task_form_title'})}
				description={formatMessage({id: 'project_settings.public_create_task_form_desc'})}
			>
				<SelectionElementLineWrapper>
					<Switch
						checked={linkToPublicCreateTaskPage !== null}
						onChange={() => changeUsePublicCreateTask(projectId, linkToPublicCreateTaskPage)}
						locked={projectDoneOrHalted}
					/>
					<SelectionElementLabel>{formatMessage({id: 'common.enable'})}</SelectionElementLabel>
				</SelectionElementLineWrapper>
				{linkToPublicCreateTaskPage && (
					<>
						<ExtraElementWrapper>
							{formatMessage({id: 'project_settings.page_link'})}
							<CopyLinkWrapper>
								<LinkBox>{publicTaskLink}</LinkBox>
								<ClipboardButton
									onSuccess={() => {
										createToast({
											duration: 5000,
											message: formatMessage({id: 'common.copied_to_clipboard'}),
										});
									}}
									className="copy-to-clipboard-button"
									component="a"
									title={formatMessage({id: 'common.copy'})}
									data-clipboard-text={publicTaskLink}
								>
									<ForecastTooltip content={formatMessage({id: 'common.copy'})}>
										<CopyIcon />
									</ForecastTooltip>
								</ClipboardButton>
							</CopyLinkWrapper>
							{
								/** there are 2 SelectionElementLineWrapper to get the 32px margin top (2*16) that is only made for this checkbox*/
								<SelectionElementLineWrapper>
									<SelectionElementLineWrapper>
										<Checkbox
											checked={usePublicCreateTaskPassword}
											onClick={handleChangeUsePublicCreateTaskPassword}
										/>
										<SelectionElementLabel>
											{formatMessage({id: 'project_settings.require_password'})}
										</SelectionElementLabel>
									</SelectionElementLineWrapper>
								</SelectionElementLineWrapper>
							}
							{usePublicCreateTaskPassword && (
								<ExtraElementWrapper>
									{formatMessage({id: 'common.password'})}
									<CopyLinkWrapper>
										<LinkBox>
											<PasswordWrapper>{getPassword()}</PasswordWrapper>
											<EyeIcon onClick={() => setShowPassword(!_showPassword)} isOpen={_showPassword} />
											<ForecastTooltip content={'Generate password'}>
												<ReloadIcon onClick={generatePassword} />
											</ForecastTooltip>
										</LinkBox>
										<ClipboardButton
											onSuccess={() => {
												createToast({
													duration: 5000,
													message: formatMessage({id: 'common.copied_to_clipboard'}),
												});
											}}
											className="copy-to-clipboard-button"
											component="a"
											title={formatMessage({id: 'common.copy'})}
											data-clipboard-text={publicCreateTaskPassword}
										>
											<ForecastTooltip content={formatMessage({id: 'common.copy'})}>
												<CopyIcon />
											</ForecastTooltip>
										</ClipboardButton>
									</CopyLinkWrapper>
								</ExtraElementWrapper>
							)}
						</ExtraElementWrapper>
						<Separator />
						<ExtraElementWrapper>
							{formatMessage({id: 'project_settings.public_task_column'})}
							<CopyLinkDropdownWrapper>
								<Dropdown
									name={formatMessage({id: 'project_settings.select_status_column'})}
									selectedItems={[publicCreateTaskPageStatusColumn.id]}
									onSelect={column => handleChangePublicCreateTaskPageStatusColumn(projectId, column[0])}
									customPlaceholder={formatMessage({id: 'empty_state_project_client.select-placeholder'})}
									width={280}
								>
									{projectColumns.map(column => (
										<Dropdown.SingleText
											key={column.node.id}
											value={column.node.id}
											searchString={column.node.name}
										>
											{column.node.name}
										</Dropdown.SingleText>
									))}
								</Dropdown>
							</CopyLinkDropdownWrapper>
						</ExtraElementWrapper>
					</>
				)}
			</SubSection>
		</>
	);
};

export default ProjectSettingsTasks;
