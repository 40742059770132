import React, {useState} from 'react';
import styled from 'styled-components';
import {
	DeprecatedCreativeModal as CreativeModal,
	DeprecatedDropdown as Dropdown,
	DeprecatedDropdownMenuColor as DropdownMenuColor,
	DeprecatedInput as Input,
	DeprecatedLabelOverComponent as LabelOverComponent,
	deprecatedTheme as theme,
	FlexItem,
	FlexRow,
	RichTextEditor,
	useRichTextEditorFunctions,
} from '@forecast-it/design-system';
import Util from '../../../util/util';
import CreateProgramMutation from '../../../../../mutations/project-service/create_program_mutation';
import {createToast} from '../../toasts/toast';
import {useHistory} from 'react-router-dom';
import {useIntl} from 'react-intl';
import DateRangePicker from '../../date-picker/date_picker_v3';
import {DATE_PICKER_STYLE} from '../../../../../constants';
import usePrefix from '../../../hooks/usePrefix';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import ProgramUtil from '../../../util/ProgramUtil';

export const DatesWrapper = styled.div`
	.date-picker-button-container-v3 {
		width: 146px;
	}
	.date-picker-button-container-v3 .date-picker-button.date-picker-button-standard {
		width: 100%;
	}
`;

export const CreateProgramModal = ({companyId, closeModal, onCreateCallback}) => {
	const {formatMessage} = useIntl();
	const history = useHistory();
	const _prefix = usePrefix();

	const [isCreating, setCreating] = useState(false);
	const [name, setName] = useState('');
	const [prefix, setPrefix] = useState('');
	const [color, setColor] = useState(theme.colors.entity.brand);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();

	const [validName, setValidName] = useState(true);
	const [validPrefix, setValidPrefix] = useState(true);

	const [userInput, setUserInput] = useState(false);

	const {editorRef, getEditorValueAsHtml} = useRichTextEditorFunctions();

	const hasName = name => !!name;
	const hasPrefix = prefix => !!prefix;

	const validateInputPrefix = prefix => {
		_prefix.validateProgramPrefix(prefix, setValidPrefix);
	};

	const generatePrefix = () => {
		const onGeneration = prefix => {
			setPrefix(prefix);
			setValidPrefix(true);
		};
		_prefix.generateProgramPrefix(name, onGeneration);
	};

	const createProgram = () => {
		const trackProgramCreated = () =>
			trackEvent('Program', 'Created', {
				fromDateSet: !!startDate,
				toDateSet: !!endDate,
				manualPrefixSet: !!userInput,
			});

		if (hasName(name) && hasPrefix(prefix) && validPrefix) {
			setCreating(true);
			const programDescription = getEditorValueAsHtml();
			Util.CommitMutation(
				CreateProgramMutation,
				{
					companyId: companyId,
					name: name,
					description: programDescription,
					prefix: prefix,
					color: color,
					startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
					endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
				},
				res => {
					trackProgramCreated();

					if (onCreateCallback) {
						onCreateCallback(res.createProgram.program.node, closeModal);
					} else {
						closeModal();
						history.push(`${ProgramUtil.programLink()}/${res.createProgram.program.node.prefix}/overview`);
					}
				}
			);
		} else {
			setValidName(hasName(name));
			setValidPrefix(hasPrefix(prefix) && validPrefix);
			createToast({
				duration: 5000,
				message: formatMessage({id: 'modal.fields.required_missing'}),
			});
		}
	};

	return (
		<CreativeModal
			title={formatMessage({id: 'common.action.create_entity'}, {entity: ProgramUtil.programText(formatMessage)})}
			closeModal={closeModal}
			onCreateHandler={createProgram}
			disabled={isCreating}
			cy={'new_program_modal'}
			size={'m'}
		>
			<FlexRow gap={'s'}>
				<FlexItem flex="0 1 80%">
					<LabelOverComponent label={formatMessage({id: 'modal.fields.field.name_required'})}>
						<Input
							size={'l'}
							error={!validName}
							onChange={e => {
								setValidName(true);
								setName(e.target.value);
							}}
							onBlur={e => {
								setValidName(hasName(e.target.value));
								if (!(userInput && prefix)) {
									generatePrefix();
								}
							}}
							data-cy={'program_name'}
						/>
					</LabelOverComponent>
				</FlexItem>
				<FlexItem flex="0 1 20%">
					<LabelOverComponent label={formatMessage({id: 'modal.fields.field.id_required'})}>
						<Input
							size={'l'}
							value={prefix}
							error={!validPrefix}
							onChange={e => {
								e.target.value = _prefix.formatter(e.target.value);
								setPrefix(e.target.value);
								setUserInput(true);
								setValidPrefix(true);
							}}
							onBlur={() => {
								if (!prefix) {
									generatePrefix();
									setUserInput(false);
								} else {
									validateInputPrefix(prefix);
								}
							}}
							maxLength={5}
							style={{textTransform: 'uppercase'}}
							data-cy={'program_id'}
						/>
					</LabelOverComponent>
				</FlexItem>
			</FlexRow>
			<FlexRow gap={'s'}>
				<FlexItem flex="1 1 20%">
					<LabelOverComponent label={formatMessage({id: 'common.color'})}>
						<Dropdown initialValue={color} onSelect={setColor} size={'l'} fit={true} data-cy={'program_color'}>
							<DropdownMenuColor />
						</Dropdown>
					</LabelOverComponent>
				</FlexItem>
				<FlexItem flex="0 1 80%">
					<LabelOverComponent label={formatMessage({id: 'common.dates'})}>
						<FlexRow>
							<DatesWrapper data-cy={'program_date'}>
								<DateRangePicker
									startDate={startDate}
									endDate={endDate}
									handleDateRangeChange={(startDate, endDate) => {
										setStartDate(startDate);
										setEndDate(endDate);
									}}
									isNewDateRange={true}
									datePickerStyle={DATE_PICKER_STYLE.STANDARD}
									highlighted={true}
								/>
							</DatesWrapper>
						</FlexRow>
					</LabelOverComponent>
				</FlexItem>
			</FlexRow>
			<FlexRow>
				<LabelOverComponent label={formatMessage({id: 'common.description'})}>
					<RichTextEditor
						placeholder={formatMessage(
							{id: 'common.placeholder.add_entity'},
							{entity: formatMessage({id: 'common.description'})}
						)}
						pluginToggles={{
							link: true,
							paragraphStyles: true,
						}}
						id={'new'}
						data-cy={'program_description'}
						ref={editorRef}
					/>
				</LabelOverComponent>
			</FlexRow>
		</CreativeModal>
	);
};
