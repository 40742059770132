import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CustomScrollDiv from '../../../shared/components/scroll-bars/custom_scroll_div';
import {getDefaultMediumClientLogo} from '../../../../components/default_avatars';
import UpdateClientMutation from '../../../../mutations/update_client_mutation';
import DeleteClientMutation from '../../../../mutations/delete_client_mutation';
import {BUTTON_COLOR, BUTTON_STYLE, isoCountries, PROJECT_STATUS} from '../../../../constants';
import RichTextItem from '../../../../components/richtext_item';
import InputField from '../../../../components/inputs/input_field';
import SelectInput from '../../../../components/inputs/select_input';
import SettingsClientContact from './settings_client_contact';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createToast} from '../../../shared/components/toasts/another-toast/toaster';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import UpdateProjectMutation from '../../../../mutations/update_project_mutation.modern';
import Util from '../../../shared/util/util';
import Warning from '../../../../components/warning';
import TooltipContainer from '../../../shared/components/tooltips/tooltip_container';
import Dropdown from '../../../shared/components/dropdowns/dropdown';
import {Button, Dropdown as DropdownWebComponent} from 'web-components';
import LockedIcon from '../../../../images/integrations/xero/locked.svg';
import {getSalesforceInstanceUrl} from './settings_clients_util';
import InlineLoader from '../../../shared/components/inline-loader/inline_loader';
import DirectApi, {clientLogoSrc, profilePicSrc} from '../../../../directApi';
import {updateProjectOnSuccess} from '../../../project-tab/projects/project-settings/ProjectSettingsMutations';

class ClientPage extends Component {
	constructor(props) {
		super(props);

		const client = this.props.client;

		this.state = {
			name: client.name ? client.name : '',
			street: client.street ? client.street : '',
			zip: client.zip ? client.zip : '',
			city: client.city ? client.city : '',
			vat: client.vat ? client.vat : '',
			notes: client.notes ? client.notes : '',
			country: client.country ? client.country : '',
			newName: '',
			newRole: '',
			newEmail: '',
			newPhone: '',
			isUploadingLogo: false,
			gearMenuExpanded: false,
			disabled: this.props.isProjectClient,
			integrationLoading: false,
			quickbooksCustomerOptions: [],
			salesforceCustomerOptions: [],
			economicCustomerOptions: [],
			hubspotCompanyOptions: [],
			pipedriveOrganizationOptions: [],
			xeroContactOptions: [],
			sageIntacctContactOptions: [],
			salesforceInstanceUrl: null,
			searchingCustomers: false,
		};
		this.fetchTimeout = null;
	}

	componentDidMount() {
		this.fetchQuickbooksCustomers();
		this.fetchEconomicCustomers();
		this.fetchXeroCustomers();
		this.fetchSageIntacctCustomers();
		// this.getHubspotCompanies();
		this.fetchSalesforceCustomers();
		this.fetchSalesforceInstanceUrl();
		this.fetchPipedriveOrganizations();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const client = nextProps.client;

		this.setState({
			name: client.name ? client.name : '',
			street: client.street ? client.street : '',
			zip: client.zip ? client.zip : '',
			city: client.city ? client.city : '',
			vat: client.vat ? client.vat : '',
			notes: client.notes ? client.notes : '',
			country: client.country ? client.country : '',
		});
	}

	handleClientChange(property, value) {
		this.setState({[property]: value});
	}

	handleNewClientContactChange(property, e) {
		this.setState({[property]: e.target.value});
	}

	handleClientSave(property) {
		const value = this.state[property] ? this.state[property] : '';
		Util.CommitMutation(UpdateClientMutation, {id: this.props.client.id, [property]: value});
	}

	handleClientNotesUpdate(editorState) {
		Util.CommitMutation(UpdateClientMutation, {id: this.props.client.id, notes: editorState});
	}

	handleCountryChange(value) {
		this.setState({country: value ? value.value : null});
		Util.CommitMutation(UpdateClientMutation, {id: this.props.client.id, country: value ? value.value : ''});
	}

	deleteClient(client, e) {
		const callbackPositive = params => {
			const onSuccess = result => {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'client.has-been-deleted'})});
				this.props.history.push('/admin/clients/');
			};

			Util.CommitMutation(
				DeleteClientMutation,
				{
					id: this.props.client.id,
					companyId: this.props.viewer.company.id,
				},
				onSuccess
			);
		};

		showModal({
			type: MODAL_TYPE.GENERIC_DELETION_WARNING,
			deleteCallback: callbackPositive,
		});
	}

	updateClientLogo() {
		const {formatMessage} = this.props.intl;
		const onSuccess = () => {
			this.setState({isUploadingLogo: false});
		};
		const file = this.file_input.files[0];
		Util.confirmFilePreviewType(file, res => {
			if (res) {
				this.setState({isUploadingLogo: true});
				Util.CommitMutation(UpdateClientMutation, {id: this.props.client.id}, onSuccess, true, {
					id: this.props.client.id,
					file: file,
				});
			} else {
				showModal({
					type: MODAL_TYPE.GENERIC,
					content: (
						<div className="default-warning-modal">
							<Warning messageId="warning_modal.profile_picture_wrong_format_title" />
							<div className="warning-part-2">
								{formatMessage({id: 'warning_modal.profile_picture_wrong_format_info'})}
							</div>
						</div>
					),
					buttons: [
						{
							text: formatMessage({id: 'common.cancel'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
					],
				});
			}
		});
	}

	deleteClientLogo() {
		Util.CommitMutation(UpdateClientMutation, {id: this.props.client.id, logoId: 'delete'});
	}

	closeGearMenu() {
		this.setState({gearMenuExpanded: false});
	}

	toggleGearMenu() {
		this.setState({gearMenuExpanded: !this.state.gearMenuExpanded});
	}

	handleEditButtonClick() {
		const {formatMessage} = this.props.intl;
		if (this.state.disabled) {
			const callbackPositive = params => {
				this.setState({disabled: false});
			};

			showModal({
				type: MODAL_TYPE.GENERIC,
				content: (
					<div className="default-warning-modal">
						<Warning messageId="warning-modal.edit-warning-title" useInfoIcon={true} />
						<div className="warning-part-2">{formatMessage({id: 'client-warning-modal.edit-warning-info'})}</div>
					</div>
				),
				buttons: [
					{
						text: formatMessage({id: 'common.no'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.yes'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: callbackPositive,
					},
				],
			});
		} else {
			this.setState({disabled: true});
		}
	}

	removeClient() {
		const {formatMessage} = this.props.intl;
		const callbackPositive = () => {
			Util.CommitMutation(
				UpdateProjectMutation,
				{
					project: this.props.project,
					clientId: null,
				},
				updateProjectOnSuccess
			);
		};

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div className="default-warning-modal">
					<Warning messageId="project_client.remove_button" />
					<div className="warning-part-2">{formatMessage({id: 'client_page.remove_client_info'})}</div>
					<div className="warning-part-2">
						{formatMessage({id: 'client_page.remove_client_yellow_box_1'}) +
							' ' +
							formatMessage({id: 'client_page.remove_client_yellow_box_2'})}
					</div>
				</div>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.remove'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: callbackPositive,
				},
			],
		});
	}

	handleAddClientUser() {
		const project_options = this.props.project
			? null
			: this.props.projects
					.filter(p => p.node.client && p.node.client.id === this.props.client.id)
					.map(p => ({
						value: p.node.id,
						label: p.node.name,
					}));
		showModal({
			type: MODAL_TYPE.ADD_CLIENT_USER,
			addClientUser: true,
			project: this.props.project,
			project_options: project_options,
			companyId: this.props.companyId,
			clientId: this.props.client.id,
		});
	}

	fetchXeroCustomers() {
		if (this.props.newXeroEnabled) {
			this.setState({integrationLoading: true});

			DirectApi.Fetch('xero/unimportedContacts').then(result => {
				const options = !Array.isArray(result.unimportedContacts)
					? []
					: result.unimportedContacts
							.sort((a, b) => {
								if (a.name.toUpperCase().trim() > b.name.toUpperCase().trim()) {
									return 1;
								} else if (a.name.toUpperCase().trim() < b.name.toUpperCase().trim()) {
									return -1;
								}
								return 0;
							})
							.map(customer => ({value: customer.id, label: `${customer.name} (${customer.org})`}));
				this.setState({
					xeroContactOptions: options,
					integrationLoading: false,
				});
			});
		}
	}

	fetchSageIntacctCustomers() {
		if (this.props.sageIntacctConnectionEnabled) {
			this.setState({integrationLoading: true});

			DirectApi.Fetch('sage_intacct/unimported_customers').then(res => {
				const options = !res?.customers
					? []
					: res.customers
							.sort((a, b) => {
								if (a.NAME.toUpperCase().trim() > b.NAME.toUpperCase().trim()) {
									return 1;
								} else if (a.NAME.toUpperCase().trim() < b.NAME.toUpperCase().trim()) {
									return -1;
								}
								return 0;
							})
							.map(customer => ({value: customer.CUSTOMERID, label: `${customer.NAME}`}));
				this.setState({
					sageIntacctContactOptions: options,
					integrationLoading: false,
				});
			});
		}
	}

	fetchPipedriveOrganizations(searchInput) {
		if (this.props.pipedriveCompanyDomain) {
			this.setState({integrationLoading: true});

			DirectApi.Fetch(`pipedrive/unimportedOrganizations?search=${searchInput ? `${searchInput}` : ''}`).then(
				organizations => {
					const options = !Array.isArray(organizations)
						? []
						: organizations
								.sort((a, b) => (a.name.toUpperCase().trim() > b.name.toUpperCase().trim() ? 1 : 0))
								.map(company => ({
									value: company.id.toString(),
									label: company.name,
								}));
					this.setState({
						pipedriveOrganizationOptions: options,
						integrationLoading: false,
					});
				}
			);
		}
	}

	fetchQuickbooksCustomers() {
		if (this.props.quickbooksEnabled) {
			this.setState({integrationLoading: true});

			DirectApi.Fetch('quickbooks/unimported_customers').then(customers => {
				if (customers?.length) {
					const options = customers
						.sort((a, b) => {
							if (a.name.toUpperCase().trim() > b.name.toUpperCase().trim()) {
								return 1;
							} else if (a.name.toUpperCase().trim() < b.name.toUpperCase().trim()) {
								return -1;
							}
							return 0;
						})
						.map(customer => ({value: customer.id, label: customer.name, realmId: customer.realmId}));
					this.setState({
						quickbooksCustomerOptions: options,
						integrationLoading: false,
					});
				}
			});
		}
	}

	fetchSalesforceCustomers() {
		if (this.props.newSalesforceEnabled) {
			this.setState({integrationLoading: true});

			DirectApi.Fetch('salesforce/unimported_accounts').then(accounts => {
				this.setState({
					salesforceCustomerOptions: accounts.list
						.sort((a, b) => (a.Name.toUpperCase().trim() > b.Name.toUpperCase().trim() ? 1 : -1))
						.map(account => ({
							value: account.Id,
							label: account.Name,
						})),
					integrationLoading: false,
				});
			});
		}
	}

	async fetchSalesforceInstanceUrl() {
		if (this.props.newSalesforceEnabled) {
			this.setState({integrationLoading: true});

			const instanceUrl = await getSalesforceInstanceUrl();

			this.setState({
				salesforceInstanceUrl: instanceUrl,
				integrationLoading: false,
			});
		}
	}

	fetchEconomicCustomers() {
		if (this.props.economicEnabled) {
			this.setState({integrationLoading: true});

			DirectApi.Fetch('economic/unimported_customers').then(customers => {
				const options = customers.list
					.sort((a, b) => {
						if (a.name.toUpperCase().trim() > b.name.toUpperCase().trim()) {
							return 1;
						} else if (a.name.toUpperCase().trim() < b.name.toUpperCase().trim()) {
							return -1;
						}
						return 0;
					})
					.map(customer => ({value: customer.id, label: customer.name}));
				this.setState({
					economicCustomerOptions: options,
					integrationLoading: false,
				});
			});
		}
	}

	linkHubspotCompany(option) {
		const callback = () => {
			if (option) {
				Util.CommitMutation(UpdateClientMutation, {
					id: this.props.client.id,
					//option is an array with one element
					hubspotCompanyId: option[0],
				});
			}
		};

		const foundOption = this.state.hubspotCompanyOptions.find(hubspotOption => hubspotOption.value === option[0]);
		const optionlabel = foundOption && foundOption.label;

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'settings.client.link_client',
			warningInformation: [
				<div className="link-client-modal-content">
					<FormattedMessage
						id="settings.client.confirm_link_customer"
						values={{linkName: optionlabel, b: chunks => <strong>{chunks}</strong>}}
					/>
				</div>,
			],
			useInfoIcon: true,
			buttons: [
				{
					text: <FormattedMessage id="common.cancel" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: <FormattedMessage id="common.link" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: callback,
				},
			],
		});
	}

	unlinkHubspotCustomer() {
		const {formatMessage} = this.props.intl;

		showModal({
			type: MODAL_TYPE.GENERIC,
			className: 'default-warning-modal',
			content: <Warning messageId="settings.client.hubspot_unlink" />,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.unlink'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: () => {
						Util.CommitMutation(UpdateClientMutation, {
							id: this.props.client.id,
							hubspotCompanyId: null,
						});
					},
				},
			],
		});
	}

	linkPipedriveOrganization(option) {
		const callback = () => {
			if (option) {
				Util.CommitMutation(UpdateClientMutation, {
					id: this.props.client.id,
					pipedriveOrganizationId: option.value,
				});
			}
		};

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'settings.client.link_client',
			warningInformation: [
				<div className="link-client-modal-content">
					<FormattedMessage
						id="settings.client.confirm_link_customer"
						values={{linkName: option.label, b: chunks => <strong>{chunks}</strong>}}
					/>
				</div>,
			],
			useInfoIcon: true,
			buttons: [
				{
					text: <FormattedMessage id="common.cancel" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: <FormattedMessage id="common.link" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: callback,
				},
			],
		});
	}

	unlinkPipedriveOrganization() {
		const {formatMessage} = this.props.intl;

		showModal({
			type: MODAL_TYPE.GENERIC,
			className: 'default-warning-modal',
			content: <Warning messageId="settings.client.pipedrive_unlink" />,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.unlink'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: () => {
						Util.CommitMutation(UpdateClientMutation, {
							id: this.props.client.id,
							pipedriveOrganizationId: null,
						});
					},
				},
			],
		});
	}

	unlinkSageIntacctCustomer() {
		const {formatMessage} = this.props.intl;

		showModal({
			type: MODAL_TYPE.GENERIC,
			className: 'default-warning-modal',
			content: <Warning messageId="settings.client.sage_intacct_unlink" />,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.unlink'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: () => {
						Util.CommitMutation(UpdateClientMutation, {
							id: this.props.client.id,
							sageIntacctCustomerId: null,
						});
					},
				},
			],
		});
	}

	linkSageIntacctCustomer(option) {
		const callback = () => {
			if (option) {
				Util.CommitMutation(UpdateClientMutation, {
					id: this.props.client.id,
					sageIntacctCustomerId: option.value.toString(),
				});
			}
		};

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'settings.client.link_client',
			warningInformation: [
				<div className="link-client-modal-content">
					<FormattedMessage
						id="settings.client.confirm_link_customer"
						values={{linkName: option.label, b: chunks => <strong>{chunks}</strong>}}
					/>
				</div>,
			],
			useInfoIcon: true,
			buttons: [
				{
					text: <FormattedMessage id="common.cancel" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: <FormattedMessage id="common.link" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: callback,
				},
			],
		});
	}

	linkXeroCustomer(option) {
		const callback = () => {
			if (option) {
				Util.CommitMutation(UpdateClientMutation, {
					id: this.props.client.id,
					xeroContactId: option.value,
				});
			}
		};

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'settings.client.link_client',
			warningInformation: [
				<div className="link-client-modal-content">
					<FormattedMessage
						id="settings.client.confirm_link_customer"
						values={{linkName: option.label, b: chunks => <strong>{chunks}</strong>}}
					/>
				</div>,
			],
			useInfoIcon: true,
			buttons: [
				{
					text: <FormattedMessage id="common.cancel" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: <FormattedMessage id="common.link" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: callback,
				},
			],
		});
	}

	unlinkXeroCustomer() {
		const {formatMessage} = this.props.intl;

		showModal({
			type: MODAL_TYPE.GENERIC,
			className: 'default-warning-modal',
			content: <Warning messageId="settings.client.xero_unlink" />,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.unlink'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: () => {
						Util.CommitMutation(UpdateClientMutation, {
							id: this.props.client.id,
							xeroContactId: null,
						});
					},
				},
			],
		});
	}

	linkQuickbooksCustomer(option) {
		const callback = () => {
			if (option) {
				Util.CommitMutation(UpdateClientMutation, {
					id: this.props.client.id,
					quickbooksCustomerId: option.value,
					quickbooksCustomerRealmId: option.realmId,
				});
			}
		};

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'settings.client.link_client',
			warningInformation: [
				<div className="link-client-modal-content">
					<FormattedMessage
						id="settings.client.confirm_link_customer"
						values={{linkName: option.label, b: chunks => <strong>{chunks}</strong>}}
					/>
				</div>,
			],
			useInfoIcon: true,
			buttons: [
				{
					text: <FormattedMessage id="common.cancel" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: <FormattedMessage id="common.link" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: callback,
				},
			],
		});
	}

	linkSalesforceCustomer(option) {
		const callback = () => {
			if (option) {
				Util.CommitMutation(UpdateClientMutation, {
					id: this.props.client.id,
					salesforceAccountId: option.value,
				});
			}
		};

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'settings.client.link_client',
			warningInformation: [
				<div className="link-client-modal-content">
					<FormattedMessage
						id="settings.client.confirm_link_customer"
						values={{linkName: option.label, b: chunks => <strong>{chunks}</strong>}}
					/>
				</div>,
			],
			useInfoIcon: true,
			buttons: [
				{
					text: <FormattedMessage id="common.cancel" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: <FormattedMessage id="common.link" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: callback,
				},
			],
		});
	}

	unlinkSalesforceCustomer() {
		const {formatMessage} = this.props.intl;

		showModal({
			type: MODAL_TYPE.GENERIC,
			className: 'default-warning-modal',
			content: <Warning messageId="settings.client.salesforce_unlink" />,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.unlink'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: () => {
						Util.CommitMutation(UpdateClientMutation, {
							id: this.props.client.id,
							salesforceAccountId: null,
						});
					},
				},
			],
		});
	}

	unlinkQuickbooksCustomer() {
		const {formatMessage} = this.props.intl;

		showModal({
			type: MODAL_TYPE.GENERIC,
			className: 'default-warning-modal',
			content: <Warning messageId="settings.client.quickbooks_unlink" />,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.unlink'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: () => {
						Util.CommitMutation(UpdateClientMutation, {
							id: this.props.client.id,
							quickbooksCustomerId: null,
						});
					},
				},
			],
		});
	}

	linkEconomicCustomer(option) {
		const callback = () => {
			if (option) {
				Util.CommitMutation(UpdateClientMutation, {
					id: this.props.client.id,
					economicCustomerId: option.value,
				});
			}
		};

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'settings.client.link_client',
			warningInformation: [
				<div className="link-client-modal-content">
					<FormattedMessage
						id="settings.client.confirm_link_customer"
						values={{linkName: option.label, b: chunks => <strong>{chunks}</strong>}}
					/>
				</div>,
			],
			useInfoIcon: true,
			buttons: [
				{
					text: <FormattedMessage id="common.cancel" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: <FormattedMessage id="common.link" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: callback,
				},
			],
		});
	}

	unlinkEconomicCustomer() {
		const {formatMessage} = this.props.intl;

		showModal({
			type: MODAL_TYPE.GENERIC,
			className: 'default-warning-modal',
			content: <Warning messageId="settings.client.economic_unlink" />,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.unlink'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: () => {
						Util.CommitMutation(UpdateClientMutation, {
							id: this.props.client.id,
							economicCustomerId: null,
						});
					},
				},
			],
		});
	}

	getHubspotCompanies(searchInput) {
		if (!this.props.hubspotAccountId || !searchInput) {
			this.setState({
				hubspotCompanyOptions: [],
				integrationLoading: false,
				searchingCustomers: false,
			});
		} else {
			this.setState({integrationLoading: true});

			DirectApi.Fetch(`hubspot/unimportedCompanies${searchInput ? `?search=${searchInput}` : ''}`).then(companies => {
				const options = !companies.list
					? []
					: companies.list
							.sort((a, b) =>
								a.properties.name.toUpperCase().trim() > b.properties.name.toUpperCase().trim() ? 1 : 0
							)
							.map(company => ({value: company.id, label: company.properties.name}));
				this.setState({
					hubspotCompanyOptions: options,
					integrationLoading: false,
					searchingCustomers: false,
				});
			});
		}
	}

	getHubspotCompaniesWithTimeout(newSearchString) {
		//send the request to hubspot service 1 second and a half after the user is done typing
		clearTimeout(this.fetchTimeout);
		this.setState({searchingCustomers: true});
		this.fetchTimeout = setTimeout(() => {
			this.getHubspotCompanies(newSearchString);
		}, 1000);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const logoSrc = this.props.client.logoId
			? clientLogoSrc(this.props.client.logoId)
			: getDefaultMediumClientLogo(this.props.client.logoDefaultId);
		const mentions = this.props.allPersons.edges
			.filter(person => {
				return person.node.active;
			})
			.map(person => {
				return {
					personId: person.node.id,
					name: person.node.firstName + ' ' + person.node.lastName,
					avatar: profilePicSrc(person.node.profilePictureId),
				};
			});
		if (this.props.client.clientUsers) {
			this.props.client.clientUsers.edges.sort((a, b) => {
				const nameA =
					(a.node.firstName ? a.node.firstName.toUpperCase() : '') +
					(a.node.lastName ? a.node.lastName.toUpperCase() : '');
				const nameB =
					(b.node.firstName ? b.node.firstName.toUpperCase() : '') +
					(b.node.lastName ? b.node.lastName.toUpperCase() : '');
				if (nameA < nameB) return -1;
				if (nameA > nameB) return 1;
				return 0;
			});
		}
		let projectOptions = null,
			personIdToProjectIdsMap = null,
			projectToProjectPersonsMap = null;
		if (this.props.projects) {
			projectOptions = this.props.projects
				.filter(project => project.node.client && project.node.client.id === this.props.client.id)
				.map(p => ({
					value: p.node.id,
					label: p.node.name,
				}));
			personIdToProjectIdsMap = new Map();
			projectToProjectPersonsMap = new Map();
			this.props.projects.forEach(project => {
				if (project.node.projectPersons) {
					project.node.projectPersons.edges.forEach(pp => {
						let personProjectIds = [];
						if (personIdToProjectIdsMap.has(pp.node.person.id)) {
							personProjectIds = personIdToProjectIdsMap.get(pp.node.person.id);
						}
						personProjectIds.push({value: project.node.id, label: project.node.name});
						personIdToProjectIdsMap.set(pp.node.person.id, personProjectIds);
					});
					projectToProjectPersonsMap.set(project.node.id, project.node.projectPersons.edges);
				}
			});
		}
		const doneOrHalted =
			this.props.isProjectClient && [PROJECT_STATUS.DONE, PROJECT_STATUS.HALTED].includes(this.props.project.status);

		const sidePadding = this.props.isProjectClient ? 32 : 0;

		return (
			<CustomScrollDiv>
				<div className="client-page" style={{padding: `0 ${sidePadding}px`}}>
					<div className="section-body">
						<div className="inner">
							<fieldset className="client-info">
								<div className="container">
									<div className="sub-section">
										<div className="top-section">
											<h2>{formatMessage({id: 'settings.client-details'})}</h2>
										</div>
										<p className="description">{formatMessage({id: 'settings.client-description'})}</p>
										<div className="client">
											<div className="titles-material">
												<InputField
													label={formatMessage({id: 'settings.client-company-name'})}
													type="text"
													placeholder={formatMessage({id: 'settings.client-enter-client-name'})}
													id="client_name"
													size="70"
													value={this.state.name}
													onChange={this.handleClientChange.bind(this, 'name')}
													onBlur={this.handleClientSave.bind(this, 'name')}
													locked={this.state.disabled}
													blurOnEnter={true}
													cy={'company-name-input'}
												/>
												<InputField
													label={formatMessage({id: 'settings.client-company-street'})}
													type="text"
													placeholder={formatMessage({id: 'settings.client-enter-client-street'})}
													id="client_street"
													size="70"
													value={this.state.street}
													onChange={this.handleClientChange.bind(this, 'street')}
													onBlur={this.handleClientSave.bind(this, 'street')}
													locked={this.state.disabled}
													blurOnEnter={true}
													cy={'company-street-input'}
												/>
												<InputField
													label={formatMessage({id: 'settings.client-company-zip'})}
													type="text"
													placeholder={formatMessage({id: 'settings.client-enter-client-zip'})}
													id="client_zip"
													size="35"
													value={this.state.zip}
													onChange={this.handleClientChange.bind(this, 'zip')}
													onBlur={this.handleClientSave.bind(this, 'zip')}
													locked={this.state.disabled}
													blurOnEnter={true}
													cy={'company-zip-input'}
												/>
												<InputField
													label={formatMessage({id: 'settings.client-company-city'})}
													type="text"
													placeholder={formatMessage({id: 'settings.client-enter-client-city'})}
													id="client_city"
													size="35"
													value={this.state.city}
													onChange={this.handleClientChange.bind(this, 'city')}
													onBlur={this.handleClientSave.bind(this, 'city')}
													locked={this.state.disabled}
													blurOnEnter={true}
													cy={'company-city-input'}
												/>
												<div className="select-wrapper">
													<SelectInput
														placeholder={formatMessage({
															id: 'settings.client-enter-client-country',
														})}
														options={isoCountries}
														value={this.state.country}
														onChange={this.handleCountryChange.bind(this)}
														label={formatMessage({id: 'settings.client-company-country'})}
														clearable={true}
														multi={false}
														locked={this.state.disabled}
														cy={'company-country-input'}
														listDataCy={'country-option'}
													/>
												</div>
												<InputField
													label={formatMessage({id: 'settings.client-company-vat'})}
													type="text"
													placeholder={formatMessage({id: 'settings.client-enter-client-vat'})}
													id="client_vat"
													size="35"
													value={this.state.vat}
													onChange={this.handleClientChange.bind(this, 'vat')}
													onBlur={this.handleClientSave.bind(this, 'vat')}
													locked={this.state.disabled}
													blurOnEnter={true}
													cy={'company-vat-input'}
												/>
											</div>
											<div>
												<div
													className={
														this.state.isUploadingLogo ? 'upload-overlay' : 'upload-overlay-hidden'
													}
												>
													<div
														className={
															this.props.isUploading ? 'uploading-gif' : 'uploading-gif-hidden'
														}
													/>
												</div>
												<img
													crossOrigin="use-credentials"
													alt="client-logo"
													src={logoSrc}
													width="140"
													height="140"
												/>
												<div className="controls">
													{this.state.disabled ? null : (
														<div className="controls">
															<div className="controls-update">
																<input
																	id="file-input"
																	type="file"
																	onChange={this.updateClientLogo.bind(this)}
																	ref={input => (this.file_input = input)}
																	onClick={e => {
																		e.target.value = null;
																	}}
																/>
																<label className="control update">
																	{formatMessage({id: 'settings_details.update-logo'})}
																</label>
															</div>
															{this.props.client.logoId ? (
																<div
																	className="control remove"
																	onClick={this.deleteClientLogo.bind(this)}
																>
																	{formatMessage({id: 'settings_details.remove-logo'})}
																</div>
															) : null}
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="spacer" />
									<div className="sub-section">
										<div className="top-section">
											<h2>{formatMessage({id: 'settings.client-company-notes'})}</h2>
											{this.props.isProjectClient && this.props.hasClientUpdatePermission ? (
												<div className="buttons-container">
													<button
														disabled={this.props.isProjectLocked}
														className="edit-button"
														onClick={this.handleEditButtonClick.bind(this)}
													>
														{this.state.disabled
															? formatMessage({id: 'common.edit'})
															: formatMessage({id: 'common.save'})}
													</button>
												</div>
											) : null}
											{this.props.isSettingsClient ? (
												<div
													className="gear-menu"
													tabIndex="0"
													onBlur={this.closeGearMenu.bind(this)}
													onClick={this.toggleGearMenu.bind(this)}
												>
													<span className="gear-icon" />
													<div
														className={
															'gear-menu-drop-down ' +
															(this.state.gearMenuExpanded ? 'expanded' : 'collapsed')
														}
													>
														<ul className="settings-drop-down">
															{this.props.invoiced ? (
																<li className="option disabled">
																	<TooltipContainer
																		tooltipInfinteDuration
																		infoText={formatMessage({
																			id: 'settings.client_delete_invoiced',
																		})}
																	>
																		<span className="delete">
																			{formatMessage({id: 'common.delete'})}
																		</span>
																	</TooltipContainer>
																</li>
															) : (
																<li className="option" onClick={this.deleteClient.bind(this)}>
																	<span className="delete">
																		{formatMessage({id: 'common.delete'})}
																	</span>
																</li>
															)}
														</ul>
													</div>
												</div>
											) : null}
										</div>
										<div className="titles">
											<p className="description">
												{formatMessage({id: 'settings.client-company-notes-description'})}
											</p>
											<div className="notes-wrapper">
												<label htmlFor="client_notes">
													<span className="notes-label">
														{formatMessage({id: 'settings.client-company-notes'})}:
													</span>
													<RichTextItem
														readOnly={this.state.disabled}
														text={this.state.notes}
														characterLimit={this.props.characterLimit}
														placeholder={formatMessage({id: 'settings.client-enter-client-notes'})}
														handleTextChange={this.handleClientNotesUpdate.bind(this)}
														mentions={mentions}
														alwaysShowControls={false}
														alwaysShowBorder={true}
														clientNotesEditor={true}
													/>
												</label>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
							<fieldset className="client-contacts">
								<div className="top-section">
									<h2>{formatMessage({id: 'settings.client-user'})}</h2>
									{this.props.hasClientUpdatePermission ? (
										<div className="buttons-container">
											<button
												disabled={this.props.isProjectLocked}
												className="edit-button"
												onClick={this.handleAddClientUser.bind(this)}
											>
												<FormattedMessage id="settings_clients.add-client-user" />
											</button>
										</div>
									) : null}
								</div>
								<p className="description">{formatMessage({id: 'settings.client-contacts-description'})}</p>
								<div className="client-user-permission-description">
									<FormattedMessage id="client_page.client_description" />
									{this.props.showBillingInfo ? <FormattedMessage id="client_page.billing_info" /> : null}
								</div>
								<table className="client-table">
									<thead>
										<tr>
											<th>{formatMessage({id: 'settings.client-contact-name'})}</th>
											<th>{formatMessage({id: 'settings.client-contact-email'})}</th>
											<th>{formatMessage({id: 'settings.client-contact-phone'})}</th>
											{this.props.project || projectOptions.length ? (
												<th className={this.props.project ? 'th-center' : ''}>
													{this.props.project
														? formatMessage({id: 'client_page.project_access'})
														: formatMessage({id: 'common.projects'})}
												</th>
											) : null}
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{this.props.client.clientUsers
											? this.props.client.clientUsers.edges.map(user => (
													<SettingsClientContact
														key={user.node.id}
														user={user.node}
														clientId={this.props.client.id}
														projectId={this.props.project ? this.props.project.id : null}
														viewerId={this.props.viewerId}
														// showMutationErrorModal={this.props.showMutationErrorModal}
														hasProjectUpdatePermission={this.props.hasProjectUpdatePermission}
														isProjectClient={this.props.isProjectClient}
														projectOptions={projectOptions}
														clientProjectPerson={
															this.props.project
																? this.props.project.projectPersons?.edges.find(
																		pp => pp.node.person.id === user.node.id
																  )
																: null
														}
														personIdToProjectIdsMap={personIdToProjectIdsMap}
														projectToProjectPersonsMap={projectToProjectPersonsMap}
														viewer={this.props.viewer}
													/>
											  ))
											: null}
									</tbody>
								</table>
							</fieldset>
							{!this.props.isProjectClient && this.props.newXeroEnabled ? (
								<fieldset className="client-integrations">
									<div className="top-section">
										<h2>Xero</h2>
									</div>
									<p className="description">
										{this.props.client.xeroContactId ? (
											<>
												<FormattedMessage
													id="settings.client.xero_customer_linked"
													values={{
														a: chunks => (
															<a
																href={`https://go.xero.com/Contacts/View/${this.props.client.xeroContactId}`}
															>
																{chunks}
															</a>
														),
													}}
												/>
												<div>
													<Button
														onClick={this.unlinkXeroCustomer.bind(this)}
														size={Button.SIZE.STANDARD}
														variant={Button.VARIANT.RED_FILLED}
													>
														<FormattedMessage id="common.unlink" />
													</Button>
												</div>
											</>
										) : (
											<>
												<FormattedMessage id="settings.client.link_xero_customer" />
												<div className="integration-customer-dropdown">
													<Dropdown
														value={null}
														options={this.state.xeroContactOptions}
														onChange={val => this.linkXeroCustomer(val)}
														label={formatMessage({id: 'settings.client.xero_customer'})}
														placeholder={formatMessage({id: 'settings.client.xero_customer'})}
														customHeight={30}
														integrationDropdown={true}
														integrationLoading={this.state.integrationLoading}
														integrationRetryToFetch={this.fetchXeroCustomers.bind(this)}
													/>
												</div>
											</>
										)}
									</p>
								</fieldset>
							) : null}
							{!this.props.isProjectClient && this.props.sageIntacctConnectionEnabled ? (
								<fieldset className="client-integrations">
									<div className="top-section">
										<h2>Sage Intacct</h2>
									</div>
									<p className="description">
										{this.props.client.sageIntacctCustomerId ? (
											<>
												<FormattedMessage
													id="settings.client.sage_intacct_customer_linked"
													values={{
														a: chunks => (
															<a
															// href={`#`} Seems like ther is not simple way to construct the link... need to figure this out. For now it's disabled and it just shows the logo.
															>
																{chunks}
															</a>
														),
													}}
												/>
												<div>
													<Button
														onClick={this.unlinkSageIntacctCustomer.bind(this)}
														size={Button.SIZE.STANDARD}
														variant={Button.VARIANT.RED_FILLED}
													>
														<FormattedMessage id="common.unlink" />
													</Button>
												</div>
											</>
										) : (
											<>
												<FormattedMessage id="settings.client.sage_intacct_customer" />
												<div className="integration-customer-dropdown">
													<Dropdown
														value={null}
														options={this.state.sageIntacctContactOptions}
														onChange={val => this.linkSageIntacctCustomer(val)}
														label={formatMessage({id: 'settings.client.sage_intacct_customer'})}
														placeholder={formatMessage({
															id: 'settings.client.sage_intacct_customer',
														})}
														customHeight={30}
														integrationDropdown={true}
														integrationLoading={this.state.integrationLoading}
														integrationRetryToFetch={this.fetchSageIntacctCustomers.bind(this)}
													/>
												</div>
											</>
										)}
									</p>
								</fieldset>
							) : null}
							{!this.props.isProjectClient && this.props.hubspotAccountId ? (
								<fieldset className="client-integrations">
									<div className="top-section">
										<h2>Hubspot</h2>
									</div>
									<p className="description">
										{this.props.client.hubspotCompanyId ? (
											<>
												<FormattedMessage
													id="settings.client.hubspot_company_linked"
													values={{
														a: chunks => (
															<a
																href={`https://app.hubspot.com/contacts/${this.props.hubspotAccountId}/company/${this.props.client.hubspotCompanyId}/`}
																target="_blank"
															>
																{chunks}
															</a>
														),
													}}
												/>
												<div>
													<Button
														onClick={this.unlinkHubspotCustomer.bind(this)}
														size={Button.SIZE.STANDARD}
														variant={Button.VARIANT.RED_FILLED}
													>
														<FormattedMessage id="common.unlink" />
													</Button>
												</div>
											</>
										) : (
											<>
												<FormattedMessage id="settings.client.link_hubspot_company" />
												<div className="integration-customer-dropdown">
													<DropdownWebComponent
														isClearable={false}
														onSelect={v => {
															this.linkHubspotCompany(v);
														}}
														name={formatMessage({id: 'settings.client.hubspot_company'})}
														searchStringChangedCallback={searchString =>
															this.getHubspotCompaniesWithTimeout(searchString)
														}
													>
														{this.state.searchingCustomers ? (
															<InlineLoader />
														) : (
															this.state.hubspotCompanyOptions
																.sort((o1, o2) => (o1.label < o2.label ? -1 : 1))
																.map(option => (
																	<DropdownWebComponent.SingleText
																		key={option.label}
																		value={option.value}
																		searchString={option.label}
																	>
																		{option.label}
																	</DropdownWebComponent.SingleText>
																))
														)}
													</DropdownWebComponent>
												</div>
											</>
										)}
									</p>
								</fieldset>
							) : null}
							{!this.props.isProjectClient && this.props.pipedriveCompanyDomain ? (
								<fieldset className="client-integrations">
									<div className="top-section">
										<h2>Pipedrive</h2>
									</div>
									<p className="description">
										{this.props.client.pipedriveOrganizationId ? (
											<>
												<FormattedMessage
													id="settings.client.pipedrive_organization_linked"
													values={{
														a: chunks => (
															<a
																href={`${this.props.pipedriveCompanyDomain}/organization/${this.props.client.pipedriveOrganizationId}`}
																target="_blank"
															>
																{chunks}
															</a>
														),
													}}
												/>
												<div>
													<Button
														onClick={this.unlinkPipedriveOrganization.bind(this)}
														size={Button.SIZE.STANDARD}
														variant={Button.VARIANT.RED_FILLED}
													>
														<FormattedMessage id="common.unlink" />
													</Button>
												</div>
											</>
										) : (
											<>
												<FormattedMessage id="settings.client.link_pipedrive_organization" />
												<div className="integration-customer-dropdown">
													<Dropdown
														value={null}
														options={this.state.pipedriveOrganizationOptions}
														onChange={val => this.linkPipedriveOrganization(val)}
														label={formatMessage({id: 'settings.client.pipedrive_organization'})}
														placeholder={formatMessage({
															id: 'settings.client.pipedrive_organization',
														})}
														customHeight={30}
														integrationDropdown={true}
														integrationLoading={this.state.integrationLoading}
														integrationRetryToFetch={this.fetchPipedriveOrganizations.bind(this)}
													/>
												</div>
											</>
										)}
									</p>
								</fieldset>
							) : null}
							{!this.props.isProjectClient && this.props.quickbooksEnabled ? (
								<fieldset className="client-integrations">
									<div className="top-section">
										<h2>Quickbooks</h2>
									</div>
									<p className="description">
										{this.props.client.quickbooksCustomerId ? (
											<>
												<FormattedMessage
													id="settings.client.quickbooks_customer_linked"
													values={{
														a: chunks => (
															<a
																href={`${process.env.QUICKBOOKS_BASE_URL}/app/customerdetail?nameId=${this.props.client.quickbooksCustomerId}`}
															>
																{chunks}
															</a>
														),
													}}
												/>
												<div>
													<Button
														onClick={this.unlinkQuickbooksCustomer.bind(this)}
														size={Button.SIZE.STANDARD}
														variant={Button.VARIANT.RED_FILLED}
													>
														<FormattedMessage id="common.unlink" />
													</Button>
												</div>
											</>
										) : (
											<>
												<FormattedMessage id="settings.client.link_quickbooks_customer" />
												<div className="integration-customer-dropdown">
													<Dropdown
														value={null}
														options={this.state.quickbooksCustomerOptions}
														onChange={val => this.linkQuickbooksCustomer(val)}
														label={formatMessage({id: 'settings.client.quickbooks_customer'})}
														placeholder={formatMessage({id: 'settings.client.quickbooks_customer'})}
														customHeight={30}
														integrationDropdown={true}
														integrationLoading={this.state.integrationLoading}
														integrationRetryToFetch={this.fetchQuickbooksCustomers.bind(this)}
													/>
												</div>
											</>
										)}
									</p>
								</fieldset>
							) : null}
							{!this.props.isProjectClient && this.props.newSalesforceEnabled ? (
								<fieldset className="client-integrations">
									<div className="top-section">
										<h2>Salesforce</h2>
									</div>
									<p className="description">
										{this.props.client.salesforceAccountId ? (
											<>
												<FormattedMessage
													id="settings.client.salesforce_account_linked"
													values={{
														a: chunks => (
															<a
																target="_blank"
																href={`${this.state.salesforceInstanceUrl}/lightning/r/Account/${this.props.client.salesforceAccountId}/view`}
															>
																{chunks}
															</a>
														),
													}}
												/>
												<div>
													<Button
														onClick={this.unlinkSalesforceCustomer.bind(this)}
														size={Button.SIZE.STANDARD}
														variant={Button.VARIANT.RED_FILLED}
													>
														<FormattedMessage id="common.unlink" />
													</Button>
												</div>
											</>
										) : (
											<>
												<FormattedMessage id="settings.client.link_salesforce_account" />
												<div className="integration-customer-dropdown">
													<Dropdown
														value={null}
														options={this.state.salesforceCustomerOptions}
														onChange={val => this.linkSalesforceCustomer(val)}
														label={formatMessage({id: 'settings.client.salesforce_account'})}
														placeholder={formatMessage({id: 'settings.client.salesforce_account'})}
														customHeight={30}
														integrationDropdown={true}
														integrationLoading={this.state.integrationLoading}
														integrationRetryToFetch={this.fetchSalesforceCustomers.bind(this)}
													/>
												</div>
											</>
										)}
									</p>
								</fieldset>
							) : null}
							{!this.props.isProjectClient && this.props.economicEnabled ? (
								<fieldset className="client-integrations">
									<div className="top-section">
										<h2>E-conomics</h2>
									</div>
									<p className="description">
										{this.props.client.economicCustomerId ? (
											<>
												<FormattedMessage
													id="settings.client.economic_customer_linked"
													values={{
														a: chunks => (
															<a href={'https://secure.e-conomic.com/sales/customers'}>
																{chunks}
															</a>
														),
													}}
												/>
												<div>
													<Button
														onClick={this.unlinkEconomicCustomer.bind(this)}
														size={Button.SIZE.STANDARD}
														variant={Button.VARIANT.RED_FILLED}
													>
														<FormattedMessage id="common.unlink" />
													</Button>
												</div>
											</>
										) : (
											<>
												<FormattedMessage id="settings.client.link_economic_customer" />
												<div className="integration-customer-dropdown">
													<Dropdown
														value={null}
														options={this.state.economicCustomerOptions}
														onChange={val => this.linkEconomicCustomer(val)}
														label={formatMessage({id: 'settings.client.economic_customer'})}
														placeholder={formatMessage({id: 'settings.client.economic_customer'})}
														customHeight={30}
														integrationDropdown={true}
														integrationLoading={this.state.integrationLoading}
														integrationRetryToFetch={this.fetchEconomicCustomers.bind(this)}
													/>
												</div>
											</>
										)}
									</p>
								</fieldset>
							) : null}
							{this.props.isProjectClient && this.props.hasProjectUpdatePermission ? (
								<fieldset className="remove-section">
									<h3>
										<FormattedMessage id="project_client.remove-section_title" />
									</h3>
									<button onClick={this.removeClient.bind(this)} disabled={doneOrHalted}>
										<FormattedMessage id="project_client.remove_button" />
										{doneOrHalted ? (
											<img
												src={LockedIcon}
												title={formatMessage({id: 'project_client.remove_done_halted'})}
												className="locked-icon"
												alt={formatMessage({id: 'project_client.remove_done_halted'})}
											/>
										) : null}
									</button>
								</fieldset>
							) : null}
						</div>
					</div>
				</div>
			</CustomScrollDiv>
		);
	}
}

ClientPage.propTypes = {
	client: PropTypes.object.isRequired,
	allPersons: PropTypes.object.isRequired,
	viewerId: PropTypes.string,
	isProjectClient: PropTypes.bool,
	isSettingsClient: PropTypes.bool,
	hasClientUpdatePermission: PropTypes.bool,
	hasProjectUpdatePermission: PropTypes.bool,
	quickbooksEnabled: PropTypes.bool,
	economicEnabled: PropTypes.bool,
};

export default injectIntl(withRouter(ClientPage));
