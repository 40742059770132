import { useIntl } from 'react-intl';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { isBetween, isWeekSelected, onKeyboardEventHandler, workingHourForTheWeek } from '../TimesheetsCalendarUtil';
import { CellText, WeekCellStyle } from '../TimesheetsCalendar_styled';
import { FlexRow, Icon, IconTooltip } from '@forecast-it/design-system';
import React from 'react';
import Util from '../../../../shared/util/util';
import { trackEvent } from '../../../../../tracking/amplitude/TrackingV2';
const WeekCell = ({ week, firstWeekOfMonth, lockedDate, timeRegistrations, currentPersonViewed, selectedDate, selectDateFromCalendar, hideWeekend, }) => {
    var _a, _b;
    const intl = useIntl();
    const firstDayOfWeek = week.days[0];
    const lastDayOfWeek = hideWeekend ? week.days[4] : week.days[6]; // If weekend is hidden there is two fewer days in the weeks day array
    const holidays = (_b = (_a = currentPersonViewed.holidayCalendar) === null || _a === void 0 ? void 0 : _a.holidayCalendarEntries) === null || _b === void 0 ? void 0 : _b.edges.map(entry => {
        const { day, month, year } = entry.node;
        return new Date(year, month - 1, day);
    });
    const timeRegistrationsForWeek = timeRegistrations.edges.filter(timeReg => {
        const date = new Date(timeReg.node.year, timeReg.node.month - 1, timeReg.node.day);
        return isBetween(date, firstDayOfWeek, lastDayOfWeek);
    });
    const totalTime = timeRegistrationsForWeek.reduce((totalTimeRegistered, timeReg) => totalTimeRegistered + timeReg.node.minutesRegistered, 0);
    let workingHours = workingHourForTheWeek(currentPersonViewed, lastDayOfWeek);
    // Find holidays in the current week
    const holidaysInWeek = (holidays === null || holidays === void 0 ? void 0 : holidays.filter(holiday => isBetween(holiday, firstDayOfWeek, lastDayOfWeek))) || [];
    // Subtract the correct working hours for each holiday
    holidaysInWeek.forEach(holiday => {
        const holidayWeekday = holiday.getDay();
        const dayKey = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][holidayWeekday];
        workingHours -= currentPersonViewed[dayKey] || 0;
    });
    const totalTimeInHoursFormatted = Util.convertMinutesToFullHour(totalTime, intl, true);
    const workingHoursFormatted = Util.convertMinutesToFullHour(workingHours, intl, true);
    const weekSelected = isWeekSelected(selectedDate, week);
    const showLock = lockedDate && (isSameDay(lockedDate, lastDayOfWeek) || isAfter(lockedDate, lastDayOfWeek));
    const showWeekFull = totalTime >= workingHours && workingHours > 0;
    const showCheckMark = !showLock && showWeekFull;
    const showWarning = !showLock && !showCheckMark && isBefore(lastDayOfWeek, new Date()) && workingHours > 0;
    return (React.createElement(WeekCellStyle, { tabIndex: 0, showWeekFull: showWeekFull, weekSelected: weekSelected, onClick: () => selectDateFromCalendar(week.days[firstWeekOfMonth ? 6 : 0], true), onKeyDown: event => onKeyboardEventHandler(event, firstDayOfWeek, true, selectDateFromCalendar) },
        React.createElement(FlexRow, null,
            React.createElement(FlexRow, { flex: '0 0 auto', justifyContent: "flex-start" },
                React.createElement(CellText, null, timeRegistrationsForWeek.length > 0 ? `${totalTimeInHoursFormatted} / ${workingHoursFormatted}` : null)),
            React.createElement(FlexRow, { flex: '1 0', justifyContent: "flex-start" },
                showLock ? (React.createElement(IconTooltip, { icon: 'lockClosed' }, "The week is locked for new time registrations")) : null,
                showCheckMark ? React.createElement(Icon, { color: 'success', icon: "checkCircle" }) : null,
                showWarning ? (React.createElement(IconTooltip, { icon: 'warning', onOpenChange: (open) => open && trackEvent('Timesheet Week Warning Tooltip', 'Opened') }, "Not enough time registered to fill your weekly timesheet")) : null,
                !showLock && !showCheckMark && !showWarning ? React.createElement("div", null) : null))));
};
export default WeekCell;
