import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, Modal, Text } from '@forecast-it/design-system';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import { isDoneTaskTimeRegistrationAllowed } from '../../../shared/util/cache/TimeRegistrationSettingsUtil';
import { dateRangeToYMD } from '../../../shared/util/DateUtil';
import CopyLastWeekValidationSection, { CopyLastWeekValidationSectionQuery } from './CopyLastWeekValidationSection';
import { TimesheetsCopyLastWeekModalFooter } from './TimesheetsCopyLastWeekModalFooter';
import { TimesheetsCopyLastWeekModalLoader } from './TimesheetsCopyLastWeekModalLoader';
import { dateRangeToText, getCopyFromDateRange, getCopyToDateRange } from './TimesheetsCopyLastWeekModalLogic';
/**
 * Modal for copying last week's timesheets
 * @param closeModal - function to close the modal
 */
export function TimesheetsCopyLastWeekModal({ personId, selectedDate, copyFullWeek, closeModal, }) {
    const [mutationSent, setMutationSent] = useState(false);
    const [copyDoneTasks, setCopyDoneTasks] = useState(false);
    const timeRegsCount = useRef(0);
    const { formatMessage } = useIntl();
    const onCopyCallback = () => {
        setMutationSent(true);
    };
    const copyFromDateRange = getCopyFromDateRange(selectedDate, copyFullWeek);
    const copyToDateRange = getCopyToDateRange(selectedDate, copyFullWeek);
    const doneTaskTimeRegistrationAllowed = isDoneTaskTimeRegistrationAllowed();
    return (React.createElement(Modal, { size: Modal.SIZE.EXTRA_LARGE, onCloseModal: closeModal },
        React.createElement(Modal.Title, null, copyFullWeek
            ? formatMessage({ id: 'timesheets.copy_previous_week' })
            : formatMessage({ id: 'timesheets.copy_previous_day' })),
        mutationSent ? (React.createElement(TimesheetsCopyLastWeekModalLoader, null)) : (React.createElement(React.Fragment, null,
            React.createElement(Modal.Content, null,
                React.createElement(Text, null,
                    `Time registrations from `,
                    React.createElement("b", null, dateRangeToText(copyFromDateRange)),
                    ` will be added to the `,
                    React.createElement("b", null, dateRangeToText(copyToDateRange)),
                    ` timesheet.`),
                React.createElement(ForecastQueryRenderer, { key: "copy-last-week-validation-section", query: CopyLastWeekValidationSectionQuery, variables: Object.assign({ personId }, dateRangeToYMD(copyFromDateRange)), showLoader: false, render: (relayProps) => (React.createElement(CopyLastWeekValidationSection, Object.assign({}, relayProps, { copyDoneTasks: copyDoneTasks, timeRegsCount: timeRegsCount }))) }),
                doneTaskTimeRegistrationAllowed && (React.createElement(React.Fragment, null,
                    React.createElement(Text, null, `Do you want to include time registrations on Done tasks as well?`),
                    React.createElement(Checkbox, { checked: copyDoneTasks, onChange: checked => setCopyDoneTasks(!!checked), labelText: 'Include time registrations on Done tasks', notBoldLabel: true, "data-cy": "include-done-checkbox" })))),
            React.createElement(TimesheetsCopyLastWeekModalFooter, { personId: personId, copyDateRange: copyFromDateRange, closeModal: closeModal, onCopyCallback: onCopyCallback, copyDone: copyDoneTasks, copyFullWeek: copyFullWeek })))));
}
