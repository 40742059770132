import React, { useMemo } from 'react';
import ImportMode from './ImportMode';
import { Button } from '@forecast-it/design-system';
import Spinner, { SpinnerStyle } from '../../shared/components/spinner/Spinner';
export const SubmitDataImport = ({ importMode, newData, existingData, onSubmit, onCancel, loading, }) => {
    const importProjects = importMode === ImportMode.ImportNewProjects;
    const importTasks = importProjects || importMode === ImportMode.ImportPhasesTasksTimeRegs;
    const personsToCreate = useMemo(() => {
        const taskAssignedEmails = importTasks
            ? newData
                .filter((row) => !!row.task_assigned_comma_seperated_emails)
                .flatMap(row => row.task_assigned_comma_seperated_emails.split(','))
            : [];
        const taskOwnerEmails = importTasks
            ? newData
                .filter((row) => !!row.task_owner_email)
                .map(row => row.task_owner_email)
            : [];
        const timeRegEmails = newData
            .filter((row) => !!row.time_registration_person_email)
            .map(row => row.time_registration_person_email);
        return [...taskOwnerEmails, ...taskAssignedEmails, ...timeRegEmails]
            .filter(e => !existingData.emails.includes(e))
            .reduce((curr, e) => (curr.includes(e) ? curr : [...curr, e]), []);
    }, [newData, existingData, importTasks]);
    const projectsToCreate = useMemo(() => newData
        .filter((row) => !!row.project_name)
        .map(row => row.project_name)
        .filter(name => {
        const projectIdMatch = name.match(/^P-?(\d+)$/);
        return projectIdMatch === null || !existingData.projects.includes(projectIdMatch[1]);
    })
        .reduce((curr, p) => (curr.includes(p) ? curr : [...curr, p]), []), [newData, existingData]);
    const clientsToCreate = useMemo(() => newData
        .filter((row) => !!row.project_client)
        .map(row => row.project_client)
        .filter(client => !existingData.clients.includes(client))
        .reduce((curr, c) => (curr.includes(c) ? curr : [...curr, c]), []), [newData, existingData]);
    const rateCardsToCreate = useMemo(() => newData
        .filter((row) => !!row.project_rate_card)
        .map(row => row.project_rate_card)
        .filter(rc => !existingData.rateCards.includes(rc))
        .reduce((curr, rc) => (curr.includes(rc) ? curr : [...curr, rc]), []), [newData, existingData]);
    const tasksToCreate = useMemo(() => newData
        .filter((row) => !!row.project_name && !!row.task_name)
        .map(row => row.project_name + '#####' + row.task_name)
        .reduce((curr, t) => (curr.includes(t) ? curr : [...curr, t]), []), [newData, existingData]);
    const timeEntriesToCreate = useMemo(() => newData.filter(row => row.time_registration_minutes && row.time_registration_minutes > 0), [newData]);
    return (React.createElement("div", null,
        React.createElement("div", { className: "sub-description" },
            React.createElement("a", { className: "bold" }, personsToCreate.length),
            " deactivated person(s) will be created.",
            personsToCreate.length > 0 ? React.createElement("br", null) : null,
            personsToCreate.length > 0 ? React.createElement("a", null, personsToCreate.join(', ')) : null),
        importProjects ? (React.createElement("div", { className: "sub-description" },
            React.createElement("a", { className: "bold" }, clientsToCreate.length),
            " client(s) will be created.",
            clientsToCreate.length > 0 ? React.createElement("br", null) : null,
            clientsToCreate.length > 0 ? React.createElement("a", null, clientsToCreate.join(', ')) : null)) : null,
        importProjects ? (React.createElement("div", { className: "sub-description" },
            React.createElement("a", { className: "bold" }, rateCardsToCreate.length),
            " rate card(s) will be created.",
            rateCardsToCreate.length > 0 ? React.createElement("br", null) : null,
            rateCardsToCreate.length > 0 ? React.createElement("a", null, rateCardsToCreate.join(', ')) : null)) : null,
        importProjects ? (React.createElement("div", { className: "sub-description" },
            React.createElement("a", { className: "bold" }, projectsToCreate.length),
            " projects will be created.")) : null,
        importTasks ? (React.createElement("div", { className: "sub-description" },
            React.createElement("a", { className: "bold" }, tasksToCreate.length),
            " tasks will be created.")) : null,
        React.createElement("div", { className: "sub-description" },
            React.createElement("a", { className: "bold" }, timeEntriesToCreate.length),
            " time entries will be created."),
        React.createElement("div", { className: "button" },
            React.createElement(Button, { onClick: onSubmit, disabled: loading, rightIcon: !loading ? 'upload' : undefined },
                "Confirm and upload data ",
                loading ? React.createElement(Spinner, { spinning: true, style: SpinnerStyle.onLight, size: 18 }) : undefined),
            React.createElement(Button, { onClick: onCancel, disabled: loading, emphasis: "medium" }, "Cancel & restart"))));
};
