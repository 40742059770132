import React, {useContext, useEffect, useState} from 'react';
import {
	Button,
	DeprecatedContentContainer as ContentContainer,
	DeprecatedDropdown as Dropdown,
	DeprecatedDropdownMenuColor as DropdownMenuColor,
	DeprecatedDropdownMenuText as DropdownMenuText,
	DeprecatedInput as Input,
	DeprecatedLabelOverComponent as LabelOverComponent,
	DeprecatedMainContainer as MainContainer,
	DeprecatedText as Text,
	FlexColumn,
	FlexItem,
	FlexRow,
	RichTextEditor,
} from '@forecast-it/design-system';
import {DatesWrapper} from '../../../shared/components/modals/program-management/CreateProgramModal';
import DateRangePicker from '../../../shared/components/date-picker/date_picker_v3';
import moment from 'moment';
import {DATE_PICKER_STYLE} from '../../../../constants';
import {ProgramContext} from '../program-overview-page/ProgramContextProvider';
import {useIntl} from 'react-intl';
import usePrefix from '../../../shared/hooks/usePrefix';
import {useHistory} from 'react-router-dom';
import {useProgram} from '../hooks/useProgram';
import {useTrackPage} from '../../../../tracking/amplitude/hooks/useTrackPage';
import Util from '../../../shared/util/util';
import UpdateProgramDatesMutation from '../../../../mutations/project-service/update_program_dates_mutation';
import {createToast} from '../../../shared/components/toasts/toast';
import UpdateProgramPrefixMutation from '../../../../mutations/project-service/update_program_prefix_mutation';
import UpdateProgramBasicsMutation from '../../../../mutations/project-service/update_program_basics_mutation';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import ClientSearchDropdown from '../shared/ClientSearchDropdown';
import UpdateClientMutation from '../../../../mutations/project-service/update_program_client_mutation';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import UpdateProgramStageMutation from '../../../../mutations/project-service/update_program_stage_mutation';
import CustomScrollDiv from '../../../shared/components/scroll-bars/custom_scroll_div';
import {hasTopDownProgramBudgetFeature} from '../../../shared/util/ProgramFinancialLogic';
import ProgramUtil from '../../../../forecast-app/shared/util/ProgramUtil';
import {hasPermission} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {isDateDisabled} from '../../../shared/util/DateUtil';
import CompanySetupUtil from '../../../shared/util/CompanySetupUtil';

function getBudgetTypeTranslationKeyBase(budgetType) {
	return 'program.budget_type.' + (budgetType ? budgetType.toLowerCase() : 'notSet');
}

const ProgramSettingsGeneralPage = () => {
	const {program, company, actualPersonId, retry} = useContext(ProgramContext);
	const {formatMessage} = useIntl();
	const _prefix = usePrefix();
	const history = useHistory();
	const _program = useProgram(program, company.id);
	const canManageProgram = ProgramUtil.canPersonManageProgram(program.members, actualPersonId);
	const showProgramBudgetSettings = canManageProgram && hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION);

	const [existingClient, setExistingClient] = useState(program?.clients?.edges[0]?.node);
	const [validPrefix, setValidPrefix] = useState(true);
	const [openChangeBudgetTypeModalCheck, setOpenChangeBudgetTypeModalCheck] = useState(false);
	useEffect(() => {
		document.title = `${formatMessage({id: 'settings.title'})} - ${program.prefix}`;
	});
	useTrackPage('Program Settings General');

	useEffect(() => {
		setExistingClient(program.clients?.edges[0]?.node);
	}, [program]);

	const createUpdateToast = field =>
		createToast({
			duration: 5000,
			message: formatMessage(
				{id: 'common.feedback.updated_entity_field'},
				{
					entity: ProgramUtil.programText(formatMessage).toLowerCase(),
					field: formatMessage({id: field}).toLowerCase(),
				}
			),
		});

	const updateProgramDates = (newStartDate, newEndDate) => {
		if (!newStartDate.isSame(moment(program.startDate)) || !newEndDate.isSame(moment(program.endDate))) {
			Util.CommitMutation(
				UpdateProgramDatesMutation,
				{
					id: program.id,
					startDate: newStartDate.format('YYYY-MM-DD'),
					endDate: newEndDate.format('YYYY-MM-DD'),
				},
				() => createUpdateToast('common.dates')
			);
		}
	};

	const updateProgramPrefix = newPrefix => {
		if (validPrefix && newPrefix !== program.prefix) {
			Util.CommitMutation(
				UpdateProgramPrefixMutation,
				{
					id: program.id,
					prefix: newPrefix,
				},
				res => {
					window.history.replaceState(
						program,
						'',
						`${ProgramUtil.programLink()}/${res.updateProgramPrefix.program.node.prefix}/settings`
					);
					createUpdateToast('common.id');
				}
			);
		}
	};

	const updateProgramName = newName => {
		if (newName !== program.name) {
			Util.CommitMutation(
				UpdateProgramBasicsMutation,
				{
					id: program.id,
					name: newName,
					description: program.description,
					color: program.color,
				},
				() => {
					createUpdateToast('common.name');
				}
			);
		}
	};

	const updateProgramClient = client => {
		const prevClientId = existingClient?.id;
		const nextClientId = client.id;
		const findClient = id => company.clients?.edges.find(client => client.node.id === id);

		if (prevClientId !== nextClientId) {
			Util.CommitMutation(
				UpdateClientMutation,
				{
					programId: program.id,
					prevClientId: prevClientId,
					...(nextClientId ? {nextClientId: nextClientId} : {}),
				},
				() => {
					trackEvent('Program', 'Client Updated', {
						clientBefore: findClient(prevClientId)?.name,
						clientAfter: findClient(nextClientId)?.name,
					});
					createUpdateToast('common.client');
				}
			);
		}
	};

	const programStages = company.programStages.edges?.map(stage => stage.node);
	const updateProgramStage = (program, stageId) =>
		Util.CommitMutation(
			UpdateProgramStageMutation,
			{
				id: program.id,
				stageId: stageId,
			},
			() => {
				trackEvent('Program', 'Stage Updated', {
					stageBefore: program.stage.name,
					stageAfter: programStages.find(stage => stage.id === stageId)?.name,
				});
				createUpdateToast('common.stage');
			}
		);

	const updateProgramColor = newColor => {
		if (newColor !== program.color) {
			Util.CommitMutation(
				UpdateProgramBasicsMutation,
				{
					id: program.id,
					name: program.name,
					description: program.description,
					color: newColor,
				},
				() => createUpdateToast('common.color')
			);
		}
	};

	const validateInputPrefix = prefix => {
		_prefix.validateProgramPrefix(prefix, setValidPrefix);
	};

	const openDeleteProgramModal = () => {
		showModal({
			type: MODAL_TYPE.DELETE_PROGRAM,
			programId: program.id,
			companyId: company.id,
			onDeleteCallback: () => history.replace('/programs'),
		});
	};

	const openBudgetTypeChangedModal = () => {
		showModal({
			type: MODAL_TYPE.PROGRAM_BUDGET_TYPE_CHANGED,
			program: program,
			company: company,
			goToBudgetCallback: () => {
				history.replace(`${ProgramUtil.programLink()}/${program.prefix}/budget`);
			},
		});
	};

	const openChangeBudgetTypeModal = () => {
		setOpenChangeBudgetTypeModalCheck(true);
		retry();
		showModal({
			type: MODAL_TYPE.CHANGE_PROGRAM_BUDGET_TYPE,
			program: program,
			company: company,
			onSaveCallback: openBudgetTypeChangedModal,
		});
	};

	// So it seems like if we retry (refetch data), the data updates within this page
	// but not in the modal (by the time the data is updated, the modal is already open)
	// as the data has already been passed and does not update when it changes (even when
	// passing a state). This hack? essentially re opens the modal if the data has changed. On the
	// front-end, it seems like the modal was only opened once.
	useEffect(() => {
		if (openChangeBudgetTypeModalCheck) {
			showModal(
				{
					type: MODAL_TYPE.CHANGE_PROGRAM_BUDGET_TYPE,
					program: program,
					company: company,
					onSaveCallback: openBudgetTypeChangedModal,
				},
				true
			);
			setOpenChangeBudgetTypeModalCheck(false);
		}
	}, [program.projectsFinancialsTotals.allTotalTimeAndExpensesAtCompletion]);

	return (
		<CustomScrollDiv>
			<MainContainer>
				<ContentContainer width={'550px'}>
					<FlexColumn gap={'xl'}>
						<Text type={'heading'} variant={'m'}>
							{formatMessage(
								{id: 'settings.details-title.entity'},
								{entity: ProgramUtil.programText(formatMessage)}
							)}
						</Text>
						<FlexRow gap={'s'}>
							<FlexItem flex="0 1 80%">
								<LabelOverComponent label={formatMessage({id: 'modal.fields.field.name_required'})}>
									<Input
										defaultValue={program.name}
										size={'l'}
										onBlur={e => {
											if (e.target.value) {
												updateProgramName(e.target.value);
											} else {
												e.target.value = program.name;
											}
										}}
										data-cy={'program_name_settings'}
									/>
								</LabelOverComponent>
							</FlexItem>
							<FlexItem flex="0 1 20%">
								<LabelOverComponent label={formatMessage({id: 'modal.fields.field.id_required'})}>
									<Input
										size={'l'}
										defaultValue={program.prefix}
										error={!validPrefix}
										onChange={e => {
											e.target.value = _prefix.formatter(e.target.value);
											if (e.target.value) validateInputPrefix(e.target.value);
										}}
										onBlur={e => {
											if (e.target.value) {
												updateProgramPrefix(e.target.value);
											} else {
												e.target.value = program.prefix;
											}
										}}
										maxLength={5}
										style={{textTransform: 'uppercase'}}
									/>
								</LabelOverComponent>
							</FlexItem>
						</FlexRow>
						<FlexRow gap={'s'}>
							<FlexItem flex="0 1 20%">
								<LabelOverComponent label={formatMessage({id: 'modal.fields.field.color_required'})}>
									<Dropdown initialValue={program.color} onSelect={updateProgramColor} size={'l'} fit={true}>
										<DropdownMenuColor />
									</Dropdown>
								</LabelOverComponent>
							</FlexItem>
							<FlexItem flex="0 1 80%">
								<LabelOverComponent label={formatMessage({id: 'common.dates'})}>
									<FlexRow>
										<DatesWrapper>
											<DateRangePicker
												startDate={program.startDate ? moment(program.startDate) : null}
												endDate={program.endDate ? moment(program.endDate) : null}
												handleDateRangeChange={updateProgramDates}
												isNewDateRange={true}
												datePickerStyle={DATE_PICKER_STYLE.STANDARD}
												highlighted={true}
												disabledDates={isDateDisabled}
											/>
										</DatesWrapper>
									</FlexRow>
								</LabelOverComponent>
							</FlexItem>
						</FlexRow>
						<FlexRow>
							<FlexItem>
								<LabelOverComponent label={formatMessage({id: 'common.stage'})}>
									<Dropdown
										initialValue={{
											text: formatMessage({
												id: 'program_stage.' + program.stage.name.toLowerCase(),
												defaultMessage: program.stage.name,
											}),
											value: program.stage.id,
										}}
										onSelect={stageId => updateProgramStage(program, stageId)}
									>
										<DropdownMenuText
											values={programStages.map(stage => ({
												text: formatMessage({
													id: 'program_stage.' + stage.name.toLowerCase(),
													defaultMessage: stage.name,
												}),
												value: stage.id,
											}))}
										/>
									</Dropdown>
								</LabelOverComponent>
							</FlexItem>
						</FlexRow>
						<FlexRow>
							<LabelOverComponent label={formatMessage({id: 'common.client'})}>
								<ClientSearchDropdown
									clients={company.clients}
									selectedClient={existingClient}
									setClient={updateProgramClient}
								></ClientSearchDropdown>
							</LabelOverComponent>
						</FlexRow>
						<FlexRow>
							<LabelOverComponent label={formatMessage({id: 'common.description'})}>
								<RichTextEditor
									pluginToggles={{
										link: true,
										paragraphStyles: true,
									}}
									data-cy={'program_description'}
									initialHtml={program.description}
									onBlur={_program.updateProgramDescription}
								/>
							</LabelOverComponent>
						</FlexRow>
					</FlexColumn>
				</ContentContainer>
				{hasTopDownProgramBudgetFeature() && showProgramBudgetSettings && CompanySetupUtil.hasFinance() && (
					<ContentContainer width={'550px'}>
						<FlexColumn gap={'xl'}>
							<Text type={'heading'} variant={'m'}>
								{formatMessage(
									{id: 'program.budget.settings'},
									{Program: ProgramUtil.programText(formatMessage)}
								)}
							</Text>
							<FlexRow justifyContent={'space-between'}>
								<FlexColumn gap={'s'}>
									<Text>{formatMessage({id: 'program.current_budget_type'}) + ':'}</Text>
									<Text type="heading" variant="s">
										{formatMessage({
											id: getBudgetTypeTranslationKeyBase(program.budgetType) + '.title',
										})}
									</Text>
									<Text>
										{formatMessage({
											id: getBudgetTypeTranslationKeyBase(program.budgetType) + '.description',
										})}
									</Text>
								</FlexColumn>
							</FlexRow>
							<FlexRow justifyContent={'space-between'} gap={'s'}>
								<FlexItem />
								<Button
									data-cy={'openChangeBudgetTypeModal'}
									type={'creative'}
									size={'l'}
									onClick={openChangeBudgetTypeModal}
								>
									{formatMessage({id: 'program.budget_type.change'})}
								</Button>
							</FlexRow>
						</FlexColumn>
					</ContentContainer>
				)}
				<ContentContainer width={'550px'}>
					<FlexRow justifyContent={'space-between'} gap={'s'}>
						<Text type={'heading'} variant={'m'}>
							{formatMessage(
								{id: 'common.action.delete_entity'},
								{entity: ProgramUtil.programText(formatMessage)}
							)}
						</Text>
						<Button type={'destructive'} size={'l'} onClick={openDeleteProgramModal}>
							{formatMessage(
								{id: 'common.action.delete_entity'},
								{entity: ProgramUtil.programText(formatMessage)}
							)}
						</Button>
					</FlexRow>
				</ContentContainer>
			</MainContainer>
		</CustomScrollDiv>
	);
};

export default ProgramSettingsGeneralPage;
