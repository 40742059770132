import { Icon, RichTextEditor, useRichTextEditorFunctions } from '@forecast-it/design-system';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { CrossIcon, Dropdown, IconText } from 'web-components';
import { WarningIcon } from 'web-components/icons';
import { WorkflowCategories } from '../../../../../../constants';
import { convertRagStatusToWarningVariant } from '../../../../../../containers/project/project_health/project_health_status/StatusIndicator';
import { CSS_CONSTANTS } from '../../../../../../css_variables';
import DeleteSprintGoalMutation from '../../../../../../mutations/DeleteSprintGoalMutation';
import UpdateSprintGoalMutation from '../../../../../../mutations/UpdateSprintGoalMutation';
import { MODAL_TYPE, showModal } from '../../../../../shared/components/modals/generic_modal_conductor';
import { convertWarningVariantToRagStatus } from '../../../../../shared/components/status/StatusUtil';
import { createToast } from '../../../../../shared/components/toasts/toast';
import Util from '../../../../../shared/util/util';
import { EVENT_ID, subscribe, unsubscribe } from '../../../../../../containers/event_manager';
const StageOptions = [
    {
        label: 'To-do',
        value: WorkflowCategories.TODO,
        icon: React.createElement(Icon, { icon: 'todo', size: "s" }),
    },
    {
        label: 'In progress',
        value: WorkflowCategories.INPROGRESS,
        icon: React.createElement(Icon, { icon: 'inProgress', size: "s" }),
    },
    {
        label: 'Done',
        value: WorkflowCategories.DONE,
        icon: React.createElement(Icon, { icon: 'done', size: "s" }),
    },
    {
        label: 'Incomplete',
        value: 'INCOMPLETE',
        icon: React.createElement(Icon, { icon: 'incomplete', size: "s" }),
    },
];
const StatusOptions = [
    {
        label: 'On track',
        value: WarningIcon.VARIANT.NO_WARNING,
        icon: React.createElement(WarningIcon, { variant: WarningIcon.VARIANT.NO_WARNING }),
    },
    {
        label: 'At risk',
        value: WarningIcon.VARIANT.WARNING,
        icon: React.createElement(WarningIcon, { variant: WarningIcon.VARIANT.WARNING }),
    },
    {
        label: 'Off track',
        value: WarningIcon.VARIANT.DANGER,
        icon: React.createElement(WarningIcon, { variant: WarningIcon.VARIANT.DANGER }),
    },
];
const GoalWrapper = styled.div `
	display: flex;
	flex-direction: column;
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 4px;
	margin-bottom: 16px;
	padding: 8px;
	gap: 12px;
`;
const HeaderWrapper = styled.div `
	display: flex;
	justify-content: space-between;
`;
const RowWrapper = styled.div `
	display: flex;
	align-items: center;
	gap: 12px;
`;
const CrossIconWrapper = styled.button `
	padding: 0;
	margin: 4px 0;
	border: none;
	border-radius: 4px;
	&:hover,
	&:focus {
		background-color: #e7e7f3;
	}
`;
const DropdownWrapper = styled.div `
	width: 150px;
`;
const setSprintGoalDescription = (sprintGoalId, prevDesc, newDesc) => {
    if (!!newDesc && prevDesc !== newDesc) {
        Util.CommitMutation(UpdateSprintGoalMutation, {
            id: sprintGoalId,
            description: newDesc,
        });
    }
};
const setSprintGoalRag = (sprintGoalId, warningVariant) => {
    const ragStatus = convertWarningVariantToRagStatus(warningVariant[0]);
    Util.CommitMutation(UpdateSprintGoalMutation, {
        id: sprintGoalId,
        statusColor: ragStatus,
    }, () => createToast({
        duration: 2000,
        message: 'Sprint Goal Updated',
    }));
};
const setSprintGoalStage = (sprintGoalId, stage) => {
    Util.CommitMutation(UpdateSprintGoalMutation, {
        id: sprintGoalId,
        stage: stage[0],
    }, () => createToast({
        duration: 2000,
        message: 'Sprint Goal Updated',
    }));
};
const deleteSprintGoal = (sprintGoalId, sprintId) => {
    const callbackPositive = () => {
        Util.CommitMutation(DeleteSprintGoalMutation, {
            id: sprintGoalId,
            sprintId: sprintId,
        }, () => createToast({
            duration: 5000,
            message: 'Sprint Goal Deleted',
        }));
    };
    showModal({
        type: MODAL_TYPE.GENERIC_DELETION_WARNING,
        deleteCallback: callbackPositive,
    });
};
const SprintGoalRow = ({ sprintGoal, sprintId }) => {
    const { id, stage, description, statusColor } = sprintGoal;
    const sprintGoalWarningVariant = convertRagStatusToWarningVariant(statusColor);
    const goalIsDone = stage === WorkflowCategories.DONE || stage === 'INCOMPLETE';
    const { editorRef, getEditorValueAsHtml } = useRichTextEditorFunctions();
    const handleCloseSlideout = useCallback(() => {
        const newDesc = getEditorValueAsHtml();
        if (newDesc) {
            setSprintGoalDescription(id, description, newDesc);
        }
    }, []);
    useEffect(() => {
        subscribe(EVENT_ID.CLOSE_SLIDEOUT, handleCloseSlideout);
        return () => {
            unsubscribe(EVENT_ID.CLOSE_SLIDEOUT, handleCloseSlideout);
        };
    }, []);
    return (React.createElement(GoalWrapper, null,
        React.createElement(HeaderWrapper, null,
            React.createElement(RowWrapper, null,
                React.createElement(DropdownWrapper, null,
                    React.createElement(Dropdown, { name: 'Set stage', selectedItems: [stage], onSelect: stage => setSprintGoalStage(id, stage), usePortal: true, arrowCollapseFix: true }, StageOptions.map(option => (React.createElement(IconText, { value: option.value, key: option.label, searchString: option.label, text: option.label }, option.icon))))),
                goalIsDone ? null : (React.createElement(DropdownWrapper, null,
                    React.createElement(Dropdown, { name: 'Set status', selectedItems: [sprintGoalWarningVariant], onSelect: warningVariant => setSprintGoalRag(id, warningVariant), usePortal: true, arrowCollapseFix: true }, StatusOptions.map(option => (React.createElement(IconText, { value: option.value, key: option.label, searchString: option.label, text: option.label }, option.icon))))))),
            React.createElement(CrossIconWrapper, { tabIndex: 0, onClick: () => deleteSprintGoal(id, sprintId) },
                React.createElement(CrossIcon, { size: CrossIcon.SIZE.SMALL }))),
        React.createElement(RowWrapper, null,
            React.createElement("div", { style: { width: '100%' } },
                React.createElement(RichTextEditor, { id: id, placeholder: `Enter a description for your sprint goal...`, onBlur: newDesc => setSprintGoalDescription(id, description, newDesc), initialHtml: description, pluginToggles: {
                        link: true,
                        paragraphStyles: true,
                        todoList: false,
                    }, "data-cy": 'sprint-goal-description', ref: editorRef })))));
};
export default SprintGoalRow;
