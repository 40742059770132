import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../css_variables';

export const TimeManagementSection = styled.div`
	margin-bottom: 40px;
`;

export const DropdownWrapper = styled.div`
	width: 145px;
`;

export const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-bottom: 24px;
`;

export const TimeFeatureToggleSection = styled.div`
	width: 566px;
	border-width: 1px 0 0;
	border-style: solid;
	border-color: ${CSS_CONSTANTS.v2_border_gray};
	padding: 24px 0;
	&:last-child {
		border-bottom-width: 1px;
	}
`;

export const NoStylingNeeded = styled.div``;

export const FeatureTitleToggle = styled.div`
	display: flex;
	margin-bottom: 16px;
`;

export const SwitchWrapper = styled.div`
	margin-right: 16px;
`;

export const TimeSubtitle = styled.span`
	font-size: 13px;
	width: 450px;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	a {
		color: ${CSS_CONSTANTS.link_text_color};
		cursor: pointer;
	}
`;

export const TimeTitle = styled.span`
	font-size: 13px;
	font-weight: 500;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

export const SliderTitle = styled.div`
	font-size: 12px;
	font-weight: 500;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

export const CheckBoxRow = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

// Check box is built in a way that does not play nice with flex, so handle manually here
export const CheckBoxSizer = styled.div`
	min-width: 18px;
`;

export const CheckboxInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const CheckboxTitle = styled.span`
	font-size: 12px;
	font-weight: 400;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

export const CheckboxSubtitle = styled.span`
	font-size: 12px;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
`;

export const ContentBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
	gap: 24px;

	background: #ffffff;

	border: 1px solid #d3d3df;
	border-radius: 8px;
`;

export const ContentBoxHeader = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
`;

export const ContentBoxInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;
