import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import ActivateSageIntacct from '../../../mutations/activate_sage_intacct_mutation';
import StartSageIntacctRecipes from '../../../mutations/start_sage_intacct_recipes_mutation';
import StopSageIntacctRecipes from '../../../mutations/stop_sage_intacct_recipes_mutation';
import ConfigChangeStartStopSageIntacctRecipesMutation from '../../../mutations/config_change_start_stop_sage_intacct_recipes_mutation';
import Util from '../../shared/util/util';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import {MODULE_TYPES} from '../../../constants';
import sageIntacctLogo from '../../../images/integrations/sage-intacct/sage-logo.png';
import {
	Alert,
	Breadcrumbs,
	Button,
	Checkbox,
	FlexColumn,
	FlexRow,
	Heading,
	Icon,
	Label,
	Link,
	RagStatusMessage,
	Tabs,
	Text,
} from '@forecast-it/design-system';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import {hasModule} from '../../shared/util/ModuleUtil';
import InlineLoader from '../../shared/components/inline-loader/inline_loader';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';

const TABS = {
	CONNECTION: 'CONNECTION',
	CONFIGURATION: 'CONFIGURATION',
};

class appSageIntacct extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activating: false,
			connected: null,
			selectedTab: TABS.CONNECTION,
			sageIntacctTimeRegsFromForecast: props.viewer.company.sageIntacctTimeRegsFromForecast,
			sageIntacctSyncOnlyApproved: props.viewer.company.sageIntacctSyncOnlyApproved,
			showConfirmation: false,
			showSavingLoader: false,
		};

		this.isSagePSA = hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED);
	}

	componentDidMount() {
		// The Workato embedded iframe sends messages when a connecion is updated.
		// We listen to these to stop/start recipes.
		window.addEventListener('message', this.connectionUpdate.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener('message', this.connectionUpdate.bind(this));
	}

	enableSageIntacct() {
		this.setState({activating: true});

		const onSuccess = data => {
			if (data.activateSageIntacct.outcome !== 'Success') {
				this.setState({activating: false});
				createToast({duration: 10000, message: 'An error occured. Please try again'});
			} else {
				this.setState({activating: false});
			}
		};

		Util.CommitMutation(ActivateSageIntacct, null, onSuccess);
	}

	connectionUpdate(e) {
		let data;

		// Sometimes a string is returned...
		if (typeof e.data === 'string' || e.data instanceof String) {
			// Just in case the string can not be parsed
			try {
				data = JSON.parse(e.data);
			} catch (e) {
				// Do nothing
			}
		} else {
			data = e.data;
		}

		if (data?.type === 'connectionStatusChange') {
			if (this.state.connected != null && data.payload?.connected) {
				const onSuccess = data => {
					if (data.startSageIntacctRecipes.outcome === 'Success') {
						createToast({duration: 10000, message: 'Connection enabled'});
					}
				};

				if (this.state.sageIntacctTimeRegsFromForecast) {
					Util.CommitMutation(UpdateCompanyMutation, {
						id: this.props.viewer.company.id,
						useTimeApproval: true,
					});
				}

				Util.CommitMutation(
					StartSageIntacctRecipes,
					{
						sageIntacctTimeRegsFromForecast: this.state.sageIntacctTimeRegsFromForecast,
						sageServiceForTimeRegsFeatureFlag: hasFeatureFlag('use_sage_service_for_time_regs'),
						sageServiceForTasksFeatureFlag: hasFeatureFlag('use_sage_service_for_tasks'),
						sageServiceForProjectsFeatureFlag: hasFeatureFlag('use_sage_service_for_projects'),
					},
					onSuccess
				);
			} else if (!data.payload?.connected) {
				const onSuccess = data => {
					if (data.stopSageIntacctRecipes.outcome === 'Success') {
						createToast({duration: 10000, message: 'Connection disabled'});
					}
				};

				Util.CommitMutation(StopSageIntacctRecipes, null, onSuccess);
			}

			if (data.payload) {
				this.setState({connected: data.payload.connected});
			}
		}
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/19276955784849-Overview-of-the-Sage-Intacct-integration');
	}

	openInstructionsGuide() {
		window.open(
			this.isSagePSA
				? 'https://www.forecast.app/hubfs/Sage%20PSA%20-%20Configuration%20Guide.pdf'
				: 'https://www.forecast.app/hubfs/Sage%20Intacct%20Integration%20-%20Configuration%20Guide.pdf'
		);
	}

	handleTabChange = newTab => {
		this.setState({selectedTab: newTab});
	};

	handleSageIntacctTimeRegsFromForecastChange = selected => {
		const sageIntacctTimeRegsFromForecast = this.isSagePSA ? selected : !selected;

		//TODO: Modify when working on T56316
		const sageIntacctSyncOnlyApproved = sageIntacctTimeRegsFromForecast;
		this.setState({
			sageIntacctTimeRegsFromForecast,
			sageIntacctSyncOnlyApproved,
			showConfirmation: false,
		});
	};

	handleSageIntacctSyncOnlyApprovedChange = selected => {
		this.setState({
			sageIntacctSyncOnlyApproved: this.sageIntacctTimeRegsFromForecast ? true : selected,
			showConfirmation: false,
		});
	};

	updateSageIntacctTimeRegsFromForecast = () => {
		this.setState({showSavingLoader: true});
		const onSuccess = () => {
			this.setState({showConfirmation: true, showSavingLoader: false});
		};

		const startStopSageIntacctRecipesOnConfigChange = () => {
			Util.CommitMutation(
				ConfigChangeStartStopSageIntacctRecipesMutation,
				{
					sageIntacctTimeRegsFromForecast: this.state.sageIntacctTimeRegsFromForecast,
				},
				onSuccess
			);
		};

		// Enforcing time approval when using Timesheets from FC
		const useTimeApproval = this.state.sageIntacctTimeRegsFromForecast
			? {
					useTimeApproval: true,
			  }
			: {};

		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: this.props.viewer.company.id,
				sageIntacctTimeRegsFromForecast: this.state.sageIntacctTimeRegsFromForecast,
				sageIntacctSyncOnlyApproved: this.state.sageIntacctSyncOnlyApproved,
				...useTimeApproval,
			},
			startStopSageIntacctRecipesOnConfigChange
		);
	};

	render() {
		const {formatMessage} = this.props.intl;
		const {
			connected,
			selectedTab,
			sageIntacctTimeRegsFromForecast,
			sageIntacctSyncOnlyApproved,
			showConfirmation,
			showSavingLoader,
		} = this.state;

		return (
			<div className="section-content settings-app settings-app-page">
				{this.props.children}
				{!this.isSagePSA && (
					<div className={'breadcrumbs-wrapper'}>
						<Breadcrumbs>
							<Breadcrumbs.Item>
								<Link href={`/settings/integrations`} data-cy="breadcrumbs-integrations-link" size="2">
									{formatMessage({id: 'settings.integrations'})}
								</Link>
							</Breadcrumbs.Item>
							<Breadcrumbs.Item>
								<Text>{formatMessage({id: 'integrations.sage_intacct'})}</Text>
							</Breadcrumbs.Item>
						</Breadcrumbs>
					</div>
				)}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							{!this.isSagePSA && !this.props.viewer.company.sageIntacctEnabled ? (
								<fieldset className="general">
									<div className="header-wrapper">
										<img height={100} alt="sageIntacct logo" src={sageIntacctLogo} />
										<div style={{marginTop: '20px'}} className="title-description-wrapper">
											<div className="description">
												{formatMessage({id: 'settings.app_catalog.sage_intacct.description'})}
											</div>
										</div>
									</div>

									<div className="qb-buttons">
										<FlexRow gap={'l'}>
											<Button
												onClick={this.enableSageIntacct.bind(this)}
												disabled={this.state.activating}
											>
												{formatMessage({
													id: 'common.activate',
												})}
											</Button>
											<Button
												onClick={this.openHelpCenter.bind(this)}
												disabled={this.state.activating}
												emphasis="low"
											>
												{formatMessage({
													id: 'settings.app_catalog.help_button',
												})}
											</Button>
										</FlexRow>
									</div>
								</fieldset>
							) : (
								<div className={'heading-wrapper'}>
									<Heading size={'1'}>
										{formatMessage({
											id: this.isSagePSA
												? 'settings.app_catalog.sage_psa.configuration_headline'
												: 'integrations.sage_intacct',
										})}
									</Heading>
								</div>
							)}

							<div className={'status'}>
								<FlexColumn>
									<Label>Status</Label>
									<RagStatusMessage
										status={
											connected === null
												? RagStatusMessage.STATUS.NEUTRAL
												: connected
												? RagStatusMessage.STATUS.GREEN
												: RagStatusMessage.STATUS.RED
										}
									>
										<RagStatusMessage.Message>
											{formatMessage({
												id:
													connected === null
														? 'common.loading'
														: connected
														? 'common.connected'
														: 'common.not_connected',
											})}
										</RagStatusMessage.Message>
									</RagStatusMessage>
								</FlexColumn>
							</div>

							{this.props.viewer.company.sageIntacctEnabled ? (
								<fieldset className="general">
									<FlexColumn gap={'l'}>
										<div className={'configuration-info'}>
											<FlexColumn>
												<Text>
													{formatMessage({
														id: this.isSagePSA
															? 'settings.app_catalog.sage_psa.configuration_description_1'
															: 'settings.app_catalog.sage_intacct.configuration_description_1',
													})}
												</Text>
												<Text>
													{formatMessage({
														id: this.isSagePSA
															? 'settings.app_catalog.sage_psa.configuration_description_2'
															: 'settings.app_catalog.sage_intacct.configuration_description_2',
													})}
													<Link onClick={this.openInstructionsGuide.bind(this)}>
														{formatMessage({
															id: 'settings.app_catalog.sage_intacct.configuration_description_instructions_guide',
														})}
													</Link>
													.
												</Text>
												{!this.isSagePSA && (
													<Text>
														{formatMessage({
															id: 'settings.app_catalog.sage_intacct.configuration_description_3',
														})}
														<Link onClick={this.openHelpCenter.bind(this)}>
															{formatMessage({
																id: 'settings.app_catalog.sage_intacct.configuration_description_help_center',
															})}
														</Link>
														.
													</Text>
												)}
											</FlexColumn>
										</div>
										<div className={'tabs-wrapper'}>
											<Tabs value={selectedTab} onValueChange={this.handleTabChange.bind(this)}>
												<Tabs.List>
													<Tabs.Tab key={TABS.CONNECTION} value={TABS.CONNECTION}>
														{formatMessage({id: 'common.connection'})}
													</Tabs.Tab>
													<Tabs.Tab key={TABS.CONFIGURATION} value={TABS.CONFIGURATION}>
														{formatMessage({
															id: 'settings.app_catalog.sage_intacct.configuration_settings',
														})}
													</Tabs.Tab>
												</Tabs.List>
											</Tabs>
										</div>

										<iframe
											title="Workato connection iframes"
											style={{
												width: '1000px',
												height: '770px',
												padding: '20px 20px 20px 0px',
												border: '0',
												display: selectedTab === TABS.CONNECTION ? 'initial' : 'none',
											}}
											src={`https://app.eu.workato.com/direct_link/embedded/connections/${this.props.viewer.company.workatoSageIntacctConnectionId}?workato_dl_token=${this.props.viewer.company.workatoIframeToken}`}
											allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
											allowFullScreen
										/>

										{selectedTab !== TABS.CONNECTION && (
											<>
												<Alert
													type={Alert.TYPE.WARNING}
													text={formatMessage({
														id: 'settings.app_catalog.sage_intacct.time_reg_warning',
													})}
												/>
												<FlexColumn gap={'l'} className={'settings-checkboxes'}>
													<Checkbox
														checked={
															this.isSagePSA
																? sageIntacctTimeRegsFromForecast
																: !sageIntacctTimeRegsFromForecast
														}
														onChange={this.handleSageIntacctTimeRegsFromForecastChange.bind(this)}
														labelText={formatMessage({
															id: this.isSagePSA
																? 'settings.app_catalog.sage_psa.time_reg_label'
																: 'settings.app_catalog.sage_intacct.time_reg_label',
														})}
														description={
															<FormattedHTMLMessage
																id={
																	this.isSagePSA
																		? 'settings.app_catalog.sage_psa.time_reg_description'
																		: 'settings.app_catalog.sage_intacct.time_reg_description'
																}
															/>
														}
													/>
													<Checkbox
														disabled={true} //TODO: Disable for everyone but re-enable it when working on T56316 (Intacct => FC)
														checked={sageIntacctSyncOnlyApproved}
														onChange={this.handleSageIntacctSyncOnlyApprovedChange.bind(this)}
														labelText={formatMessage({
															id: 'settings.app_catalog.sage_intacct.sync_approved_label',
														})}
														description={formatMessage({
															id: this.isSagePSA
																? 'settings.app_catalog.sage_psa.sync_approved_description'
																: 'settings.app_catalog.sage_intacct.sync_approved_description',
														})}
													/>
												</FlexColumn>
												<FlexRow gap={'m'}>
													<Button
														onClick={this.updateSageIntacctTimeRegsFromForecast.bind(this)}
														disabled={showConfirmation || showSavingLoader}
													>
														{formatMessage({
															id: 'settings.app_catalog.sage_intacct.update_button_text',
														})}
													</Button>
													{showConfirmation && (
														<FlexRow>
															<Icon icon={'checkCircle'} color={'success'} />{' '}
															<Text>
																{formatMessage({
																	id: 'common.settings_saved',
																})}
															</Text>
														</FlexRow>
													)}
													{showSavingLoader && !showConfirmation && (
														<FlexRow>
															<div
																className="content-loading"
																style={{height: '10px', width: '10px'}}
															>
																<InlineLoader />
															</div>
															<Text>
																{formatMessage({
																	id: 'common.saving',
																})}
															</Text>
														</FlexRow>
													)}
												</FlexRow>
											</>
										)}
									</FlexColumn>
								</fieldset>
							) : null}
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appSageIntacctQuery = graphql`
	query appSageIntacct_Query {
		viewer {
			actualPersonId
			component(name: "app_sage_intacct")
			...appSageIntacct_viewer
		}
	}
`;

export {appSageIntacctQuery};

export default createFragmentContainer(injectIntl(withRouter(appSageIntacct)), {
	viewer: graphql`
		fragment appSageIntacct_viewer on Viewer {
			id
			company {
				id
				sageIntacctEnabled
				workatoCustomerId
				workatoForecastSageIntacctConnectionId
				workatoSageIntacctConnectionId
				workatoIframeToken
				sageIntacctTimeRegsFromForecast
				sageIntacctSyncOnlyApproved
			}
		}
	`,
});
