import React, {useEffect, useMemo, useState} from 'react';

import {createRefetchContainer, graphql} from 'react-relay';
import * as tracking from '../../../tracking';
import {useIntl} from 'react-intl';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../shared/components/headers/top-header-bar/TopHeaderBar';
import {Button, SubNavigation, Table} from 'web-components';
import Styled from 'styled-components/macro';
import CustomFieldDefinitionRow from './CustomFieldDefinitionRow';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import Util from '../../shared/util/util';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import {hasModule} from '../../shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../constants';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';

const HeaderBar = Styled.div`
	margin: 20px 24px 0;
	display: flex;
	justify-content: space-between;
`;

const ContentBody = Styled.div`
	margin: 20px 0;
`;

const ENTITY_TYPES = ['PROJECT', 'PHASE', 'TASK', 'TIME_REG'];

const SettingsCustomFields = ({viewer}) => {
	const {formatMessage} = useIntl();
	const [selectedEntityType, setSelectedEntityType] = useState(ENTITY_TYPES[0]);

	useTrackPage('Custom Fields');

	useEffect(() => {
		document.title = 'Custom Fields - Account - Forecast';
		tracking.trackPage('settings-custom-fields');
	}, []);

	const customFieldDefinitions = useMemo(() => {
		return viewer.company.customFieldDefinitions.edges
			.map(edge => edge.node)
			.filter(customFieldDefinition => customFieldDefinition.entityType === selectedEntityType)
			.sort((a, b) => Util.sortAlphabetically(a.key, b.key));
	}, [viewer.company.customFieldDefinitions.edges, selectedEntityType]);

	const createCustomFieldDefinition = () => {
		showModal({
			type: MODAL_TYPE.CUSTOM_FIELD_DEFINITION,
			entityType: selectedEntityType,
		});
	};

	return (
		<div className="section-content settings-app settings-custom-fields" data-cy={'settings-custom-fields'}>
			<TopHeaderBarWrapper>
				<TopHeaderBar title={formatMessage({id: 'settings.custom_fields.title'})} />
			</TopHeaderBarWrapper>
			<HeaderBar>
				<SubNavigation>
					{ENTITY_TYPES.map(entityType => (
						<SubNavigation.NavigationItem
							key={'sub-navigation-' + entityType}
							active={selectedEntityType === entityType}
							onClick={() => setSelectedEntityType(entityType)}
							cy={`custom-fields-${entityType}`}
						>
							{formatMessage({id: 'settings.custom-fields.entity-type.' + entityType})}
						</SubNavigation.NavigationItem>
					))}
				</SubNavigation>
				<div>
					<Button
						size={Button.SIZE.STANDARD}
						variant={Button.VARIANT.PURPLE_FILLED}
						onClick={createCustomFieldDefinition}
						cy={`create-custom-fields-definition`}
					>
						{formatMessage({id: 'settings.custom-fields.create'})}
					</Button>
				</div>
			</HeaderBar>

			<CustomScrollDiv>
				<ContentBody>
					<Table paddedCells>
						<Table.Header>
							<Table.HeaderColumn visible usePadding width={Table.TABLE_COLUMN_WIDTH.EXTRA_LARGE}>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'settings.custom-fields.key'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn visible usePadding width={Table.TABLE_COLUMN_WIDTH.EXTRA_LARGE}>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'settings.custom-fields.displayName'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								visible
								usePadding
								width={Table.TABLE_COLUMN_WIDTH.SMALL}
								align={Table.TABLE_COLUMN_ALIGNMENT.CENTER}
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'settings.custom-fields.readOnly'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								visible={
									hasModule(MODULE_TYPES.SISENSE) &&
									(selectedEntityType === 'PROJECT' || selectedEntityType === 'TASK') &&
									hasFeatureFlag('custom_field_ava_refs')
								}
								usePadding
								width={Table.TABLE_COLUMN_WIDTH.EXTRA_LARGE}
								align={Table.TABLE_COLUMN_ALIGNMENT.LEFT}
							>
								<Table.HeaderColumn.Title>{`Advanced Analytics reference`}</Table.HeaderColumn.Title>
							</Table.HeaderColumn>

							<Table.HeaderColumn
								flex={1}
								visible
								usePadding
								width={Table.TABLE_COLUMN_WIDTH.SMALL}
								align={Table.TABLE_COLUMN_ALIGNMENT.RIGHT}
							/>
						</Table.Header>
						<Table.Rows data={{rows: customFieldDefinitions}} keyFunc={row => row.id}>
							{({rowData: customFieldDefinition, tableColumnsProps}) => (
								<CustomFieldDefinitionRow
									company={viewer.company}
									customFieldDefinition={customFieldDefinition}
									tableColumnsProps={tableColumnsProps}
								/>
							)}
						</Table.Rows>
					</Table>
				</ContentBody>
			</CustomScrollDiv>
		</div>
	);
};

export const SettingsCustomFieldsQuery = graphql`
	query SettingsCustomFieldsQuery {
		viewer {
			actualPersonId
			component(name: "settings_custom_fields")
			...SettingsCustomFields_viewer
		}
	}
`;

export default createRefetchContainer(SettingsCustomFields, {
	viewer: graphql`
		fragment SettingsCustomFields_viewer on Viewer {
			actualPersonId
			id
			company {
				...CustomFieldDefinitionRow_company
				customFieldDefinitions(first: 1000) @connection(key: "Company_customFieldDefinitions") {
					edges {
						node {
							id
							key
							entityType
							...CustomFieldDefinitionRow_customFieldDefinition
						}
					}
				}
			}
		}
	`,
});
