/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query SettingsDataImporter_Query {
  viewer {
    actualPersonId
    component(name: "data_importer")
    ...SettingsDataImporter_viewer
    id
  }
}

fragment SettingsDataImporter_viewer on Viewer {
  email
  company {
    id
    allCompanyTaskIds
    allCompanyProjectIds
    allPersons(first: 10000) {
      edges {
        node {
          id
          email
        }
      }
    }
    clients(first: 10000) {
      edges {
        node {
          id
          name
        }
      }
    }
    rateCards(first: 10000) {
      edges {
        node {
          id
          name
        }
      }
    }
    roles(first: 10000) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
*/
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "data_importer"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"data_importer\")"
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 10000
        }
    ], v5 = [
        (v3 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "SettingsDataImporter_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "SettingsDataImporter_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "SettingsDataImporter_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "allCompanyTaskIds",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "allCompanyProjectIds",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "PersonTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "allPersons",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PersonTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Person",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "allPersons(first:10000)"
                                },
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "ClientTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "clients",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ClientTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Client",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": (v5 /*: any*/),
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "clients(first:10000)"
                                },
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "RateCardTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "rateCards",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "RateCardTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "RateCard",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": (v5 /*: any*/),
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "rateCards(first:10000)"
                                },
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "RoleTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "roles",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "RoleTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Role",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": (v5 /*: any*/),
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "roles(first:10000)"
                                }
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "SettingsDataImporter_Query",
            "operationKind": "query",
            "text": "query SettingsDataImporter_Query {\n  viewer {\n    actualPersonId\n    component(name: \"data_importer\")\n    ...SettingsDataImporter_viewer\n    id\n  }\n}\n\nfragment SettingsDataImporter_viewer on Viewer {\n  email\n  company {\n    id\n    allCompanyTaskIds\n    allCompanyProjectIds\n    allPersons(first: 10000) {\n      edges {\n        node {\n          id\n          email\n        }\n      }\n    }\n    clients(first: 10000) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    rateCards(first: 10000) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    roles(first: 10000) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = 'b3d1556c8066edaf1e90a5de0d2a3d14';
export default node;
